import { IExpandedSubMenu, IMenuItem, INavItem } from '@layout/nav/interfaces/nav.type';
import { NODA_DOCS_REFERENCE_LINK } from '@shared/constants/links';

export const availableResourcesRoutes: string[] = [
  '/glossary',
  '/articles',
  '/newsroom',
];

export const availableMoreRoutes: string[] = [
  '/pricing',
  '/glossary',
  '/articles',
  '/contact-us',
  '/events',
];

export const availableDeveloperRoutes: string[] = [
  '/plugins',
  '/demo',
];

export const developersNavList: IExpandedSubMenu = {
  label: 'common.header.developers',
  children: [
    {
      items: [
        {
          icon: 'tech',
          subIcon: 'external-link',
          subtitle: 'common.developers.tech-documentation.title',
          href: 'https://docs.noda.live/',
          children: [
            {
              subtitle: 'common.developers.ob-payments-api',
              href: `${NODA_DOCS_REFERENCE_LINK}/create-a-payment`,
            },
            {
              subtitle: 'common.developers.card-payments-api',
              href: `https://docs.noda.live/reference/create-a-card-payment`,
            },
            {
              subtitle: 'common.developers.bank-payouts-api',
              href: `${NODA_DOCS_REFERENCE_LINK}/create-a-payout`,
            },
            {
              subtitle: 'common.developers.card-payouts-api',
              href: `https://docs.noda.live/reference/create-a-card-payout`,
            },
            {
              subtitle: 'common.developers.pay-and-go',
              href: `https://docs.noda.live/reference/pl-register-new-users`,
            },
          ],
        },
        {
          icon: 'demo',
          subtitle: 'common.developers.open-banking-demo.title',
          subtext: 'common.developers.open-banking-demo.subtitle',
          link: '/demo',
          children: [
            {
              subtitle: 'common.products.pay-ins.open-banking-payments.subtitle',
              link: '/demo/payment-flow',
            },
            {
              subtitle: 'common.developers.pay-and-go',
              link: '/demo/pay-and-go',
            },
          ],
        },
        {
          icon: 'plugins',
          subtitle: 'common.developers.noda-plugins.title',
          subtext: 'common.developers.noda-plugins.subtitle',
          link: '/plugins',
        },
      ],
    },
  ],
};

export const blogNavList: IMenuItem[] = [
  {
    label: 'common.header.articles',
    link: '/articles',
  },
  {
    label: 'common.header.case-studies',
    link: '/articles',
    params: { tag: 'case-studies' },
  },
  {
    label: 'common.header.newsroom',
    link: '/newsroom',
  },
  {
    label: 'common.header.glossary',
    link: '/glossary',
  },
];

export const productsNavList: IExpandedSubMenu = {
  label: 'common.header.products',
  children: [
    {
      label: 'common.products.pay-ins.title',
      items: [
        {
          icon: 'banking',
          subtitle: 'common.products.pay-ins.open-banking-payments.subtitle',
          subtext: 'common.products.pay-ins.open-banking-payments.subtext',
          link: '/open-banking',
        },
        {
          icon: 'card-payments',
          subtitle: 'common.products.pay-ins.card-payments.subtitle',
          subtext: 'common.products.pay-ins.card-payments.subtext',
          link: '/card-payments',
        },
        {
          icon: 'checkout',
          subtitle: 'common.products.pay-ins.checkout-form.subtitle',
          subtext: 'common.products.pay-ins.checkout-form.subtext',
          link: '/checkout',
        },
        {
          icon: 'product',
          subtitle: 'common.products.pay-ins.no-code-payment.subtitle',
          subtext: 'common.products.pay-ins.no-code-payment.subtext',
          link: '/no-code-payment-page',
        },
        {
          icon: 'instant-payment-links',
          subtitle: 'common.products.pay-ins.instant-payment-links.subtitle',
          subtext: 'common.products.pay-ins.instant-payment-links.subtext',
          link: '/payment-link',
        },
      ],
    },
    {
      label: 'common.products.pay-outs.title',
      items: [
        {
          icon: 'bank-payouts',
          subtitle: 'common.products.pay-outs.bank-payouts.subtitle',
          subtext: 'common.products.pay-outs.bank-payouts.subtext',
          link: '/bank-payouts',
        },
        {
          icon: 'card-payouts',
          subtitle: 'common.products.pay-outs.card-payouts.subtitle',
          subtext: 'common.products.pay-outs.card-payouts.subtext',
          link: '/card-payouts',
        },
      ],
    },
    {
      label: 'common.products.financial-data.title',
      items: [
        {
          icon: 'sign',
          subtitle: 'common.products.financial-data.sign-in-via-bank.subtitle',
          subtext: 'common.products.financial-data.sign-in-via-bank.subtext',
          link: '/bank-sign',
        },
        {
          icon: 'financial',
          subtitle: 'common.products.financial-data.know-your-whales.subtitle',
          subtext: 'common.products.financial-data.know-your-whales.subtext',
          link: '/customer-lifetime-value-prediction',
        },
      ],
    },
  ],
};

export const useCasesNavList: IExpandedSubMenu = {
  label: 'common.header.use-cases',
  children: [
    {
      items: [
        {
          icon: 'ecommerce',
          subtitle: 'common.use-cases.ecommerce.subtitle',
          subtext: 'common.use-cases.ecommerce.subtext',
          link: '/use-cases/ecommerce',
        },
        {
          icon: 'saas',
          subtitle: 'common.use-cases.saas.subtitle',
          subtext: 'common.use-cases.saas.subtext',
          link: '/use-cases/saas',
        },
        {
          icon: 'travel',
          subtitle: 'common.use-cases.travel.subtitle',
          subtext: 'common.use-cases.travel.subtext',
          link: '/use-cases/travel',
        },
        {
          icon: 'marketplace',
          subtitle: 'common.use-cases.marketplaces.subtitle',
          subtext: 'common.use-cases.marketplaces.subtext',
          link: '/use-cases/marketplaces',
        },
        {
          icon: 'education',
          subtitle: 'common.use-cases.educational-platforms.subtitle',
          subtext: 'common.use-cases.educational-platforms.subtext',
          link: '/use-cases/educational-platforms',
        },
        {
          icon: 'retail',
          subtitle: 'common.use-cases.retail.subtitle',
          subtext: 'common.use-cases.retail.subtext',
          link: '/use-cases/retail',
        },
        {
          icon: 'gaming',
          subtitle: 'common.use-cases.online-gaming.subtitle',
          subtext: 'common.use-cases.online-gaming.subtext',
          link: '/use-cases/online-gaming',
        },
      ],
    },
  ],
};

export const aboutNavList: IExpandedSubMenu = {
  label: 'common.header.about',
  children: [
    {
      items: [
        {
          icon: 'company',
          subtitle: 'common.about.company.subtitle',
          subtext: 'common.about.company.subtext',
          link: '/about/company',
        },
        {
          icon: 'security',
          subtitle: 'common.about.security.subtitle',
          subtext: 'common.about.security.subtext',
          link: '/about/security-and-data-handling',
        },
        {
          icon: 'ux-approach',
          subtitle: 'common.about.ux-approach.subtitle',
          subtext: 'common.about.ux-approach.subtext',
          link: '/about/customer-experience',
        },
        {
          icon: 'mission',
          subtitle: 'common.about.mission.subtitle',
          subtext: 'common.about.mission.subtext',
          link: '/about/who-we-are',
        },
        {
          icon: 'legal-privacy',
          subtitle: 'common.about.legal-privacy.subtitle',
          subtext: 'common.about.legal-privacy.subtext',
          link: '/about/legal-privacy/terms',
        },
        {
          icon: 'partnership',
          subtitle: 'common.about.partnership.subtitle',
          subtext: 'common.about.partnership.subtext',
          link: '/about/partnerships',
        },
        {
          icon: 'why-open-banking',
          subtitle: 'common.about.why-open-banking.subtitle',
          subtext: 'common.about.why-open-banking.subtext',
          link: '/about/why-open-banking',
        },
        {
          icon: 'risk-policies',
          subtitle: 'common.about.risk-policies.subtitle',
          subtext: 'common.about.risk-policies.subtext',
          link: '/about/risk-appetite-policy',
        },

        {
          icon: 'box',
          subtitle: 'common.about.white-label.title',
          subtext: 'common.about.white-label.subtext',
          link: '/about/grow-your-business-with-noda',
        },
      ],
    },
  ],
};

export const moreNavList: IMenuItem[] = [
  {
    label: 'common.header.marketplace',
    isHidden: false,
    link: '/marketplace',
  },
  {
    label: 'common.header.pricing',
    link: '/pricing',
  },
  {
    label: 'common.header.articles',
    link: '/articles',
  },
  {
    label: 'common.header.case-studies',
    link: '/articles',
    params: { tag: 'case-studies' },
  },
  {
    label: 'common.header.newsroom',
    link: '/newsroom',
  },
  {
    label: 'common.header.glossary',
    link: '/glossary',
  },
  {
    label: 'common.contact.contact-us',
    link: '/contact-us',
  },
];

export const mobileNavList: INavItem[] = [
  productsNavList as INavItem,
  useCasesNavList as INavItem,
  aboutNavList as INavItem,
  developersNavList as INavItem,
  {
    label: 'common.header.pricing',
    link: '/pricing',
  },
  {
    label: 'common.header.marketplace',
    link: '/marketplace',
  },
  {
    label: 'common.header.resources',
    children: blogNavList,
  },
  {
    label: 'common.contact.contact-us',
    link: '/contact-us',
  },
];

export const menuItems: IMenuItem[] = [
  { label: 'common.header.products', isHidden: false },
  { label: 'common.header.use-cases', isHidden: false },
  { label: 'common.header.about', isHidden: false },
  { label: 'common.header.developers', isHidden: false },
  {
    label: 'common.header.marketplace',
    isHidden: false,
    link: '/marketplace',
  },
  { label: 'common.header.pricing', isHidden: false, link: '/pricing' },
  { label: 'common.contact.contact-us', isHidden: false, link: '/contact-us' },
];
