import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IEventsBlockTypes } from '@pages/events/interfaces/IEvents';

import { EventsHeaderBarImports } from './events-header-bar.imports';

@Component({
  selector: 'noda-events-header-bar',
  standalone: true,
  templateUrl: './events-header-bar.component.html',
  styleUrls: ['./events-header-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: EventsHeaderBarImports,
})
export class EventsHeaderBarComponent {
  @HostBinding('style.--padding')
  @Input()
  public padding: string;
  @Input() public heroEvent: IEventsBlockTypes;
}
