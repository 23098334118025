import { ChangeDetectionStrategy, Component, inject, input, InputSignal } from '@angular/core';
import { EnCAEvents } from '@core/cross-analytics/enums/cross-analytics.enum';
import { DemoPageAnalyticsService } from '@core/cross-analytics/services/demo-page.analytics.service';
import { TButtonSize } from '@core/types/button';
import { navActionsComponentImports } from '@layout/nav/actions/nav-actions.imports';

@Component({
  selector: 'noda-nav-actions',
  templateUrl: './nav-actions.component.html',
  styleUrls: ['./nav-actions.component.scss'],
  standalone: true,
  imports: navActionsComponentImports,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavActionsComponent {
  public demoPageAnalyticsService: DemoPageAnalyticsService = inject(DemoPageAnalyticsService);

  public href: InputSignal<string> = input<string>();
  public isSelfTarget: InputSignal<boolean> = input<boolean>(false);
  public btnSize: InputSignal<TButtonSize> = input<TButtonSize>('s');

  public sendCaEvent(): void {
    this.demoPageAnalyticsService.trackOutsideClick(EnCAEvents.START_USING_NODA);
  }
}
