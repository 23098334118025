import { Injectable, WritableSignal } from '@angular/core';
import { ExtendsFactory, State } from '@noda-lib/utils/managers';

import { CartViewFlags } from '../states/cart.view.flags';

@Injectable()
export class CartViewService extends ExtendsFactory(
  State({
    flags: CartViewFlags,
  }),
) {
  public toggleCartOpen(value?: boolean): void {
    if (value === undefined) {
      const currentValue: WritableSignal<boolean> = this.getState('flags', 'isCartOpen');
      this.getState('flags', 'isCartOpen').set(!currentValue());
    } else {
      this.getState('flags', 'isCartOpen').set(value);
    }
  }

  public toggleMobileCartOpen(value?: boolean): void {
    if (value === undefined) {
      const currentValue: WritableSignal<boolean> = this.getState('flags', 'isMobileCartOpen');
      this.getState('flags', 'isMobileCartOpen').set(!currentValue());
    } else {
      this.getState('flags', 'isMobileCartOpen').set(value);
    }
  }
}
