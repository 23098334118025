import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { EnFooterStatus } from '@core/enums/footer-status.enum';
import { BaseNavService } from '@core/services/base-nav-service';
import { NavService } from '@core/services/nav.service';
import { FooterThemeService } from '@layout/services/footer-theme.service';
import { totalBalanceDarkImages, totalBalanceWhiteImages } from '@layout/wrapper/wrapper.db';
import { WrapperImports } from '@layout/wrapper/wrapper.imports';
import { EventsService } from '@pages/events/services/events.service';
import { EnMinWidthScreen } from '@shared/constants/screen-breakpoints';
import { ICardImages } from '@shared/db/card.interface';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'noda-wrapper',
  standalone: true,
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseNavService, useExisting: NavService }],
  imports: WrapperImports,
})
export class WrapperComponent implements AfterViewInit {
  private router: Router = inject(Router);
  public eventService: EventsService = inject(EventsService);
  public footerThemeService: FooterThemeService = inject(FooterThemeService);

  public isEvent: boolean;
  public paddingRight: string = '0';
  public minWidthScreen: typeof EnMinWidthScreen = EnMinWidthScreen;
  public enFooterStatus: typeof EnFooterStatus = EnFooterStatus;
  public hideCartOnFormPage: WritableSignal<boolean> = signal(false);

  public url: Signal<string> = toSignal(
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd): string => {
        const isFormPage: boolean = event.url.includes('contact-sales');

        this.hideCartOnFormPage.set(isFormPage);

        return event.url;
      }),
    ),
  );
  public footerStatus: Signal<EnFooterStatus> = computed(() => this.setStatus(this.url()));
  public isContactSalesPage: Signal<boolean> = computed(() => this.url().includes('contact-sales'));

  public readonly totalBalanceWhiteImages: ICardImages = totalBalanceWhiteImages;
  public readonly totalBalanceDarkImages: ICardImages = totalBalanceDarkImages;

  ngAfterViewInit(): void {
    this.isEvent = Boolean(this.eventService.heroBlock$);
  }

  private setStatus(url: string): EnFooterStatus {
    switch (true) {
      case url.includes('plugins'):
        if (url.split('plugins/')[1]?.length > 0) {
          return EnFooterStatus.SINGLE_PLUGIN_VIEW;
        }

        return EnFooterStatus.PLUGINS_VIEW;
      case url.includes('demo'):
        return EnFooterStatus.NONE;
      case url.includes('use-cases/online-gaming'):
        return EnFooterStatus.ONLINE_GAMING_VIEW;
      default:
        return EnFooterStatus.DEFAULT_VIEW;
    }
  }
}
