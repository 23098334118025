import { ChangeDetectionStrategy, Component, inject, input, InputSignal } from '@angular/core';
import { UtmParametersService } from '@core/services/utm-parameters.service';
import { navItemImports } from '@layout/nav/item/nav-item.imports';

@Component({
  selector: 'noda-nav-item',
  standalone: true,
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navItemImports,
})
export class NavItemComponent {
  public utmService: UtmParametersService = inject(UtmParametersService);

  public link: InputSignal<string> = input<string>();
  public href: InputSignal<string> = input<string>();
  public isActiveLink: InputSignal<boolean> = input<boolean>();
  public isSelfTarget: InputSignal<boolean> = input<boolean>();
}
