import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@env/environment';

// import { create } from 'rxjs-spy';
import { AppComponent } from './app/app.component';
import { config } from './app/app.config';

if (environment.production) {
  enableProdMode();
}
// else {
//   const spy = create();
//   spy.show();
// }

bootstrapApplication(AppComponent, config).catch(err => console.error(err));
