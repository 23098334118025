import { inject, Provider } from '@angular/core';
import { GA_EVENT_PREFIX } from '@core/constants/google.constants';
import { provideGTMAnalytics, withGTMExtraEventOptions } from '@noda-lib/analytics';
import { CrossAnalyticsService, toGTMOptions } from '@noda-lib/cross-analytics';
import { map } from 'rxjs';

export function provideAnalytics(): Provider[] {
  return provideGTMAnalytics(
    {
      eventPrefix: GA_EVENT_PREFIX,
      eventCategory: 'User',
    },
    withGTMExtraEventOptions(() => {
      const caService: CrossAnalyticsService = inject(CrossAnalyticsService);

      return () => caService.selectUserInfo().pipe(map(toGTMOptions));
    }),
  );
}
