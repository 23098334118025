import { ICardImages } from '@shared/interfaces/card.interface';

export const totalBalanceDarkImages: ICardImages = {
  picture: 'footer/total-balance-xxl',
  pictureLarge: 'footer/total-balance-lg.webp',
  pictureMedium: 'footer/total-balance-md.webp',
  pictureXlarge: 'footer/total-balance-xxl.webp',
};

export const totalBalanceWhiteImages: ICardImages = {
  picture: 'footer/white-total-balance-xxl',
  pictureLarge: 'footer/white-total-balance-lg.webp',
  pictureMedium: 'footer/white-total-balance-md.webp',
  pictureXlarge: 'footer/white-total-balance-xxl.webp',
};
