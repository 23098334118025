<noda-nav-item
  class="nav-item-wrapper"
  [href]="href()"
  [isActiveLink]="isActiveLink"
  [isSelfTarget]="isSelfTarget()"
  [link]="link()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <noda-translate
    content
    [key]="label()"
  />
  <ng-container
    content
    [ngTemplateOutlet]="labelTemplate()"
  />
</noda-nav-item>
