<nav
  #scrollDirective="scrollDirective"
  nodaScroll
  class="nav header"
  [class.mobile-header]="isMobileMenuOpen()"
>
  @if (!scrollDirective.isScrolled) {
    <noda-nav-logo />

    <!--  DESKTOP VIEW  -->
    <ul *nodaResponsiveIf="[minWidthScreen.LG]">
      @for (menuItem of menuItems(); track $index) {
        <noda-nav-menu-item
          [href]="menuItem.href"
          [isDesktop]="true"
          [isSelfTarget]="menuItem.isSelfTarget"
          [label]="menuItem.label"
          [link]="menuItem.link"
        />
      }
      @if (blogNavList()) {
        <noda-sub-menu
          *nodaResponsiveIf="[minWidthScreen.XXL]"
          [activeMenuItem]="activeMenuItem()"
          [items]="blogNavList()"
          [mainLabel]="'common.header.resources'"
        />
      }
      @if (moreNavList()) {
        <noda-sub-menu
          *nodaResponsiveIf="[maxWidthScreen.XXL]"
          [activeMenuItem]="activeMenuItem()"
          [items]="moreNavList()"
          [mainLabelTemplate]="iconMore"
        />

        <ng-template #iconMore>
          <assets-icon
            class="more-icon"
            [icon]="'more_vert'"
          />
        </ng-template>
      }
    </ul>
    <!---->

    <!--  ACTIONS-->
    <noda-nav-actions>
      <ng-container actions>
        <!--        TODO: Enable multi-language support across the application-->
        <!--        TODO: Additionally, implement logic to hide the language switcher on legal documentation pages-->
        <ng-container *nodaHideLangSwitcher>
          <noda-language-switcher *nodaResponsiveIf="['(min-width: 468.9px)']" />
        </ng-container>
        <noda-nav-burger *nodaResponsiveIf="[maxWidthScreen.LG]" />
      </ng-container>
    </noda-nav-actions>
  }
</nav>

<!--  DESKTOP VIEW  -->
<ng-container *nodaResponsiveIf="[minWidthScreen.LG]">
  <noda-expanded-sub-menu [activeMenuItem]="activeMenuItem()" />
</ng-container>

<!--MOBILE VIEW-->
<ng-container *nodaResponsiveIf="[maxWidthScreen.LG]">
  @if (isMobileMenuOpen()) {
    <noda-mobile-menu
      [class.scrolled]="scrollDirective.isScrolled"
      [switcherAvailable]="true"
    />
  }
</ng-container>
