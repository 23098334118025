import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(
    private router: Router,
    @Inject(DOCUMENT) protected document: Document,
  ) {}

  public setCanonicalURL(): void {
    this.router.events
      .pipe(
        filter(
          (event: Event | RouterEvent): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe(() => {
        const url = new URL(this.router.url, 'https://noda.live');
        this.addCanonicalTag(`${url.origin}${url.pathname}`);
      });
  }

  private addCanonicalTag(href: string): void {
    let link: HTMLLinkElement = this.document.querySelector("link[rel='canonical']");
    if (link) {
      link.href = href;
    } else {
      link = this.document.createElement('link');
      link.rel = 'canonical';
      link.href = href;
      this.document.head.appendChild(link);
    }
  }
}
