import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { LanguageService } from '@translate/services/language.service';
import { Observable, of } from 'rxjs';

import { AVAILABLE_LANGS, DEFAULT_LANG, TranslateLang } from '../constants/available-langs';

export const languageGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<UrlTree | boolean> => {
  const router: Router = inject(Router);
  const languageService: LanguageService = inject(LanguageService);
  const lang: string = route.params['lang'];

  if (AVAILABLE_LANGS.includes(lang as TranslateLang) && lang !== DEFAULT_LANG) {
    if (languageService.activeLang !== lang) languageService.switch(lang);

    return of(true);
  }

  return of(router.createUrlTree(['/404']));
};
