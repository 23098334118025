import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AppAnalyticsService } from '@core/cross-analytics/services/app.analytics.service';
import { CanonicalService } from '@core/services/canonical-service.service';
import { MarketplaceViewService } from '@core/services/marketplace/marketplace.view.service';
import { UtmParametersService } from '@core/services/utm-parameters.service';
import { MARKETPLACE_STORE, MarketplaceStore } from '@core/store/marketplace.store';
import { PlatformService } from '@noda-lib/utils/services';
import { RxStore } from '@noda-lib/utils/stores';
import { filter, take } from 'rxjs';

import { AppImports, AppProviders } from './app.imports';

@Component({
  selector: 'noda-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: AppImports,
  providers: AppProviders,
})
export class AppComponent implements AfterViewInit, OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private storeService: RxStore<MarketplaceStore> = inject(MARKETPLACE_STORE);
  private analyticsService: AppAnalyticsService = inject(AppAnalyticsService);
  private utmService: UtmParametersService = inject(UtmParametersService);
  private canonicalService: CanonicalService = inject(CanonicalService);
  private router: Router = inject(Router);
  private platformService: PlatformService = inject(PlatformService);
  private marketplaceViewService: MarketplaceViewService = inject(MarketplaceViewService);

  ngOnInit(): void {
    if (this.platformService.isBrowser()) {
      this.utmService.init();
    }

    this.subscribeToQueryParams();

    this.marketplaceViewService.initActionsListener();
    this.marketplaceViewService.initErrorsListener();
    this.marketplaceViewService.initLanguageChangeListener();
    this.marketplaceViewService.loadRecommended();

    this.analyticsService.init();
  }

  ngAfterViewInit(): void {
    this.canonicalService.setCanonicalURL();
  }

  private subscribeToQueryParams(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        this.updateStoreQueryParams();
      });
  }

  private updateStoreQueryParams(): void {
    const { ...utm }: Params = this.activatedRoute.snapshot.queryParams;
    const exclude: string[] = ['cart'];

    if (utm && Object.keys(utm).length > 0) {
      const filteredUtm: Params = Object.entries(utm).reduce<Params>((acc, [key, value]) => {
        if (!exclude.includes(key)) {
          acc[key] = value;
        }

        return acc;
      }, {});

      this.storeService.setValue('urlQuery', filteredUtm);
    }
  }
}
