@if (titleTemplate()) {
  <ng-container *ngTemplateOutlet="titleTemplate(); context: { title: title() }" />
} @else {
  @if (title() && titleLink()) {
    <a
      class="title text-3-v2--hover"
      [queryParamsHandling]="'merge'"
      [routerLink]="titleLink() | addLangPrefix | async"
    >
      <b>
        <noda-translate [key]="title()" />
      </b>
    </a>
  }
  @if (title() && !titleLink()) {
    <p class="title text-3-v2">
      <b>
        <noda-translate [key]="title()" />
      </b>
    </p>
  }
}

<ng-container [ngTemplateOutlet]="titleDivider()" />

@for (itemLink of filteredItems(); track $index) {
  @if (itemLink.staticLink) {
    <a
      nodaManageLinkRel
      class="text-3-v2--fade-color text-3-v2--hover"
      [href]="itemLink | map: addParamsToUrl"
      [target]="itemLink.isSelfTarget ? '_self' : '_blank'"
    >
      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{ itemLink }"
      />
    </a>
  } @else if (itemLink.params) {
    <a
      queryParamsHandling="merge"
      class="text-3-v2--fade-color text-3-v2--hover"
      [queryParams]="itemLink.params"
      [routerLink]="itemLink.link | addLangPrefix | async"
    >
      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{ itemLink }"
      />
    </a>
  } @else {
    <a
      queryParamsHandling="merge"
      class="text-3-v2--fade-color text-3-v2--hover"
      [routerLink]="itemLink.link | addLangPrefix | async"
    >
      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{ itemLink }"
      />
    </a>
  }
}

<ng-template
  #item
  let-itemLink="itemLink"
>
  <noda-translate
    *ngIf="!itemTemplate()"
    [key]="itemLink.text"
  />
  <ng-container
    [ngTemplateOutlet]="itemTemplate()"
    [ngTemplateOutletContext]="{ itemLink }"
  />
</ng-template>
