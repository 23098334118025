import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  InputSignal,
  Signal,
} from '@angular/core';
import { expandedSubMenuComponentImports } from '@layout/nav/components/expanded-sub-menu/expanded-sub-menu.imports';
import {
  aboutNavList,
  developersNavList,
  productsNavList,
  useCasesNavList,
} from '@layout/nav/db/header.db';
import { EnExpandedMenuItemType } from '@layout/nav/enums/nav.enum';
import { IExpandedSubMenu } from '@layout/nav/interfaces/nav.type';

@Component({
  selector: 'noda-expanded-sub-menu',
  standalone: true,
  templateUrl: './expanded-sub-menu.component.html',
  styleUrls: ['./expanded-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: expandedSubMenuComponentImports,
})
export class ExpandedSubMenuComponent {
  public readonly menuItemType: typeof EnExpandedMenuItemType = EnExpandedMenuItemType;

  public isActive: Signal<boolean> = computed(() =>
    Object.values(this.menuItemType).includes(this.activeMenuItem() as EnExpandedMenuItemType),
  );

  public activeMenuItem: InputSignal<string> = input<string>();

  public getExpandedSubMenuItems(activeMenuItem: string): IExpandedSubMenu {
    const menuMappings: Record<string, IExpandedSubMenu> = {
      [EnExpandedMenuItemType.Products]: productsNavList,
      [EnExpandedMenuItemType.UseCases]: useCasesNavList,
      [EnExpandedMenuItemType.About]: aboutNavList,
      [EnExpandedMenuItemType.Developers]: developersNavList,
    };

    return menuMappings[activeMenuItem];
  }
}
