@if (viewFlags.isMobileCartOpen()) {
<noda-shadow
  *nodaResponsiveIf="['(max-width: 576px)']"
  [contentCenter]="false"
/>
} @if ((counter$ | async) !== 0) { @if (!viewFlags.isCartOpen()) {
<button
  *nodaResponsiveIf="['(min-width: 1024px)']"
  type="button"
  class="cart-control"
  @toggleButton
  (click)="toggleCart()"
>
  <ng-container *ngTemplateOutlet="svgArrow" />

  <div class="cart-control__counter">
    <ng-container *ngTemplateOutlet="cartCounter" />
  </div>
</button>
} @if (!viewFlags.isMobileCartOpen() && !viewFlags.isCartOpen()) {
<button
  type="button"
  class="cart-control-mobile"
  (click)="toggleCart()"
>
  <ng-container *ngTemplateOutlet="cartCounter" />
</button>
} } @if (viewFlags.isCartOpen() || viewFlags.isMobileCartOpen()) {
<div
  class="cart"
  @toggleCart
>
  <button
    type="button"
    class="cart__closer"
    (click)="toggleCart()"
  >
    <assets-icon
      icon="close"
      class="icon-close"
    />

    <ng-container *ngTemplateOutlet="svgArrow" />
  </button>

  <div class="cart-wrap">
    <h4>
      <i18n-translate key="marketplace.cart.in-your-package" />
    </h4>

    <div class="counter">
      <span class="">{{ counter$ | async }}</span>
      <i18n-translate key="marketplace.cart.products" />
    </div>

    <div>
      <button
        queryParamsHandling="preserve"
        tuiButton
        class="btn btn-start-using"
        [routerLink]="'/contact-sales' | addLangPrefix | async"
        (click)="onStartClick()"
      >
        <i18n-translate key="marketplace.cart.cta" />
        <assets-icon
          icon="arrow_drop_right"
          [size]="22"
        />
      </button>
    </div>

    <div class="content content--selected">
      <div class="title"></div>
      <div class="list">
        @for (product of selectedProducts$ | async; track $index) {
        <noda-cart-product-card
          actionType="remove"
          [product]="product"
          [useSidebarStyles]="true"
          (toggle)="onProductToggle(product, $event)"
        />
        }
      </div>
    </div>

    <div class="content content--related">
      <div class="title">
        <i18n-translate key="marketplace.cart.recommended" />:
      </div>

      <div class="list">
        @for (product of recommendedProducts$ | async; track $index) {
        <noda-cart-product-card
          actionType="add"
          [product]="product"
          [useRelatedStyles]="true"
          [useSidebarStyles]="true"
          (toggle)="onProductToggle(product, $event)"
        />
        }
      </div>
    </div>
  </div>
</div>
}

<ng-template #svgArrow>
  <div class="arrow-wrapper">
    <svg-icon
      name="icons/icon-double-arrow.svg#iconDoubleArrow"
      class="icon-arrow"
    />
  </div>
</ng-template>

<ng-template #cartCounter>
  <span class="cart-control-counter">
    <svg-icon
      name="icons/icon-cart.svg#cart"
      class="icon-cart"
      [size]="24"
    />

    <sup class="p4-website">{{ counter$ | async }}</sup>
  </span>
</ng-template>
