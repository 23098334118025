import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FooterThemeService {
  public darkThemeEnabled: WritableSignal<boolean> = signal(false);

  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public currentTheme$: Observable<string | undefined> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => {
      let route: ActivatedRoute = this.activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }

      return route.snapshot.data['theme'];
    }),
  );

  constructor() {
    this.currentTheme$.pipe(untilDestroyed(this)).subscribe(value => {
      const darkThemeEnabled: boolean = value === 'dark';

      this.darkThemeEnabled.set(darkThemeEnabled);
    });
  }
}
