import { ApplicationRef, inject, Injectable, OnDestroy } from '@angular/core';
import { EnCAEvents, EnCAStorage } from '@core/cross-analytics/enums/cross-analytics.enum';
import { DemoPageAnalyticsService } from '@core/cross-analytics/services/demo-page.analytics.service';
import { RoutingService } from '@core/services/routing.service';
import { NodaMonitoringService } from '@noda/monitoring';
import { CrossAnalyticsService } from '@noda-lib/cross-analytics';
import { WINDOW } from '@noda-lib/utils/injection';
import { PlatformService } from '@noda-lib/utils/services';
import { LanguageService } from '@translate/services/language.service';
import { Subject, take } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppAnalyticsService implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private window: Window = inject(WINDOW);
  private appRef: ApplicationRef = inject(ApplicationRef);
  private platformService: PlatformService = inject(PlatformService);
  private caService: CrossAnalyticsService = inject(CrossAnalyticsService, { optional: true });
  private routingService: RoutingService = inject(RoutingService);
  private languageService: LanguageService = inject(LanguageService);
  private demoPageAnalyticsService: DemoPageAnalyticsService = inject(DemoPageAnalyticsService, {
    optional: true,
  });
  private monitoringService: NodaMonitoringService = inject(NodaMonitoringService, {
    optional: true,
  });

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init(): void {
    if (this.platformService.isBrowser()) {
      this.initBeforeUnloadListener();
      this.initNavigationListener();
      this.sendUserLandedEvent();
      this.sessionTimeInit();
      this.initMonitoring();
    }
  }

  private initBeforeUnloadListener(): void {
    this.window.addEventListener('beforeunload', () => {
      this.caService.post({
        eventType: EnCAEvents.USER_LEFT,
        customData: {
          lang: this.languageService.activeLang,
        },
      });
    });
  }

  private initNavigationListener(): void {
    const origin: string = this.window.location.origin;
    this.routingService
      .listenNavigationEnd(true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const prevUrl: string = this.routingService.getPreviousRoute().split('?')[0];
        const currUrl: string = this.routingService.getCurrentRoute().split('?')[0];

        this.demoPageAnalyticsService.trackUrl(prevUrl, currUrl);
        this.caService.post(
          {
            eventType: EnCAEvents.USER_LEFT,
            customData: {
              lang: this.languageService.activeLang,
            },
          },
          `${origin}${prevUrl}`,
        );
        this.caService.post(
          {
            eventType: EnCAEvents.USER_LANDED,
            customData: {
              lang: this.languageService.activeLang,
            },
          },
          `${origin}${currUrl}`,
        );
      });
  }

  private initMonitoring(): void {
    this.monitoringService?.startRecord();
  }

  private sendUserLandedEvent(): void {
    this.caService.post({
      eventType: EnCAEvents.USER_LANDED,
      customData: {
        lang: this.languageService.activeLang,
      },
    });
  }

  private sessionTimeInit(): void {
    this.appRef.isStable
      .pipe(
        filter((isStable: boolean) => isStable),
        take(1),
      )
      .subscribe(() => {
        const initUrl: string = this.window.location.pathname;
        const sessionStartTime: string = Date.now().toString();
        window.sessionStorage.setItem(EnCAStorage.SESSION_INIT, sessionStartTime);
        this.demoPageAnalyticsService.setFeatureInitTime(initUrl);
      });
  }
}
