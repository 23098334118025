import { EnvironmentProviders, Provider } from '@angular/core';
import { provideAssetsLibrary } from '@noda-lib/assets';
import { provideFormErrorsMap } from '@noda-lib/assets/form';
import { withLocalizeFn } from '@noda-lib/assets/localize';
import { getTranslate } from '@noda-lib/translate';
import { TagsMap } from '@noda-lib/utils/types';
import { TranslateKey } from 'i18n';

export function provideAssets(): (EnvironmentProviders | Provider)[] {
  return [
    provideAssetsLibrary(
      withLocalizeFn((key: TranslateKey, params: TagsMap) => getTranslate(key, params)),
    ),
    provideFormErrorsMap({
      required: 'marketplace.errors.required',
      emailPattern: 'marketplace.errors.email-pattern',
      oninvalid: 'marketplace.errors.oninvalid',
    }),
  ];
}
