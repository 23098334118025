<header
  *nodaAccordionToggle="isActive()"
  class="submenu-header"
  [class.open]="isActive()"
>
  <noda-expanded-sub-items
    class="expanded-sub-items"
    [class.developers]="activeMenuItem() | equal : menuItemType.Developers"
    [isDefaultView]="activeMenuItem() | notEqual : menuItemType.Products"
    [items]="activeMenuItem() | map : getExpandedSubMenuItems"
  />
</header>
