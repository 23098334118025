<div
  #dropdownMenu="nodaDropdownMenu"
  nodaDropdownMenu
  class="menu-container"
>
  <button [nodaDropdownTrigger]="dropdownMenu">
    <div class="lang lang--selected">
      <noda-flag
        size="small"
        [country]="service.activeLang | map : getCountryFromLangCode"
      />
      <span class="text">{{ service.activeLang | uppercase }}</span>
      <svg-icon
        class="custom"
        [class.rotated]="dropdownMenu.isOpen"
        [name]="'controls/arrow-right.svg#arrow-right'"
        [size]="10"
      />
    </div>
  </button>
  <div
    *ngIf="dropdownMenu.isOpen"
    class="menu-items"
    [nodaDropdownTrigger]="dropdownMenu"
  >
    <button
      *ngFor="let lang of availableLangs; trackBy: trackByLang"
      class="menu-item"
      (click)="changeLang(lang)"
    >
      <div class="lang">
        <noda-flag
          size="small"
          [country]="lang | map : getCountryFromLangCode"
        />
        <span class="text">{{ lang | uppercase }}</span>
      </div>
    </button>
  </div>
</div>
