import { EnSnowflakeColor } from '@layout/footer/components/new-year-post-footer/new-year-post-footer.enum';
import { ISnowflake } from '@layout/types/layout.type';

export const snowflakes: ISnowflake[] = [
  {
    id: 1,
    color: EnSnowflakeColor.White,
    rotation: -28.7,
    size: 170,
    opacity: 0.3,
    strokeWidth: 4,
  },
  {
    id: 2,
    color: EnSnowflakeColor.White,
    rotation: -28.7,
    size: 60,
    opacity: 1,
    strokeWidth: 4,
  },
  {
    id: 3,
    color: EnSnowflakeColor.Violet,
    rotation: -9.5,
    size: 230,
    opacity: 1,
    strokeWidth: 5,
  },
  {
    id: 4,
    color: EnSnowflakeColor.White,
    rotation: -28.7,
    size: 65,
    opacity: 0.7,
    strokeWidth: 4,
  },
  {
    id: 5,
    color: EnSnowflakeColor.White,
    rotation: 28.7,
    size: 160,
    opacity: 1,
    strokeWidth: 4,
  },
  {
    id: 6,
    color: EnSnowflakeColor.Violet,
    rotation: 0,
    size: 90,
    opacity: 1,
    strokeWidth: 4,
  },
  {
    id: 7,
    color: EnSnowflakeColor.Violet,
    rotation: -9.5,
    size: 200,
    opacity: 0.4,
    strokeWidth: 4.5,
  },
];
