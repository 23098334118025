import { Injectable } from '@angular/core';
import { DARK_HEADER_MODE_ROUTES, WHITE_MODE_ROUTES } from '@core/constants/theme-mode.constants';
import { EnThemeMode } from '@core/enums/theme-mode.enum';
import { RoutingService } from '@core/services/routing.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeCache: Map<string, EnThemeMode> = new Map<string, EnThemeMode>();
  public currentThemeMode$: BehaviorSubject<EnThemeMode> = new BehaviorSubject<EnThemeMode>(
    EnThemeMode.Default,
  );

  constructor(private routingService: RoutingService) {
    this.applyTheme();
  }

  public applyTheme(): void {
    this.routingService.getCurrentRoute$
      .pipe(
        map(route => ({
          route,
          theme: this.getCachedTheme(route),
        })),
        tap(({ theme }) => this.updateTheme(theme)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getThemeForRoute(route: string): EnThemeMode {
    let themeMode: string = 'default';
    const isDarkHeaderModeRoute: boolean = DARK_HEADER_MODE_ROUTES.some((value: string) =>
      route?.includes(value),
    );
    const isWhiteModeRoute: boolean = WHITE_MODE_ROUTES.some((value: string) =>
      route?.includes(value),
    );

    if (isDarkHeaderModeRoute) {
      themeMode = 'darkHeaderMode';
    } else if (isWhiteModeRoute) {
      themeMode = 'whiteMode';
    }

    switch (themeMode) {
      case 'darkHeaderMode':
        return EnThemeMode.DarkHeader;
      case 'whiteMode':
        return EnThemeMode.White;
      default:
        return EnThemeMode.Default;
    }
  }

  private getCachedTheme(route: string): EnThemeMode {
    const theme: EnThemeMode = this.themeCache.get(route) || this.getThemeForRoute(route);
    if (!this.themeCache.has(route)) {
      this.themeCache.set(route, theme);
    }

    return theme;
  }

  public updateTheme(themeClass: EnThemeMode): void {
    this.currentThemeMode$.next(themeClass);
  }
}
