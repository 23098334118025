export enum EnMenuItemType {
  Products = 'common.header.products',
  UseCases = 'common.header.use-cases',
  About = 'common.header.about',
  Developers = 'common.header.developers',
  Resources = 'common.header.resources',
  More = '...',
}

export enum EnExpandedMenuItemType {
  Products = 'common.header.products',
  UseCases = 'common.header.use-cases',
  About = 'common.header.about',
  Developers = 'common.header.developers',
}
