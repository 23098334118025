@if (eventService.heroBlock$ | async) {
  <noda-events-header-bar
    [heroEvent]="eventService.heroBlock$ | async"
    [padding]="paddingRight"
  />
}
@if (!isContactSalesPage()) {
  <noda-nav />
} @else {
  <noda-contact-form-header />
}

<main [class.padding-top]="eventService.heroBlock$ | async">
  <router-outlet />
</main>

@if (!hideCartOnFormPage()) {
  <noda-cart />
}
@if (!isContactSalesPage()) {
  @if (footerStatus() | notEqual: enFooterStatus.NONE) {
    <div class="pre-footer-container">
      @switch (footerStatus()) {
        @case (enFooterStatus.SINGLE_PLUGIN_VIEW) {
          <noda-pre-footer
            buttonText="plugins.pre-footer.single-page-plugin.button"
            data-header-class="white"
            subtitle="plugins.pre-footer.single-page-plugin.subtitle"
            text="plugins.pre-footer.single-page-plugin.text"
            title="plugins.pre-footer.single-page-plugin.title"
            class="section"
          />
        }
        @case (enFooterStatus.PLUGINS_VIEW) {
          <noda-pre-footer
            buttonText="plugins.pre-footer.button"
            data-header-class="white"
            subtitle="plugins.pre-footer.subtitle"
            text="plugins.pre-footer.text"
            title="plugins.pre-footer.title"
            class="section"
          />
        }
        @case (enFooterStatus.ONLINE_GAMING_VIEW) {
          <noda-pre-footer
            id="gaming-experts"
            buttonText="Start conversation"
            data-header-class="white"
            subtitle="common.footer.lets-get-in-touch"
            title="Talk to our experts for a&nbsp;tailored Payment Solution"
            class="section online-gaming"
            [text]="null"
          />
        }
        @default {
          <noda-pre-footer
            buttonText="common.call-to-action.get-started"
            class="section"
            [attr.data-header-class]="footerThemeService.darkThemeEnabled() ? 'dark' : 'white'"
            [darkModeEnable]="footerThemeService.darkThemeEnabled()"
          />
        }
      }
      <noda-image-card
        *nodaResponsiveIf="[minWidthScreen.MD]"
        class="total-balance"
        [item]="footerThemeService.darkThemeEnabled() ? totalBalanceDarkImages : totalBalanceWhiteImages"
      />
    </div>
    <noda-footer
      [attr.data-header-class]="footerThemeService.darkThemeEnabled() ? 'dark' : 'white'"
      [darkModeEnable]="footerThemeService.darkThemeEnabled()" />
  }
}
