import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  InputSignal,
  OnInit,
  Signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CustomBreakpointService } from '@core/services/breakpoint.service';
import { MarketplaceStoreFacade } from '@core/store/services/marketplace.store.facade';
import { TProductEntity } from '@core/utils/products/entities/product.entity';
import { map, Observable, pairwise, skip } from 'rxjs';

import { cartAnimations } from './animations/cart.animations';
import { CartImports, CartProviders } from './imports/cart.imports';
import { CartAnalyticsService } from './services/cart.analytics.service';
import { CartViewService } from './services/cart.view.service';
import { CartViewFlags } from './states/cart.view.flags';

@Component({
  selector: 'noda-cart',
  standalone: true,
  imports: CartImports,
  providers: CartProviders,
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  animations: cartAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent implements OnInit {
  private breakpoint: CustomBreakpointService = inject(CustomBreakpointService);
  private viewService: CartViewService = inject(CartViewService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private analyticsService: CartAnalyticsService = inject(CartAnalyticsService);
  private storeFacade: MarketplaceStoreFacade = inject(MarketplaceStoreFacade);

  public isModalFormOpen: InputSignal<boolean> = input<boolean>(false);

  private isMobileScreen: Signal<boolean> = toSignal(
    this.breakpoint.pipe(map(value => value === 'mobileLarge')),
  );

  public selectedProducts$: Observable<TProductEntity[]> = this.storeFacade.selectedProducts$;

  public recommendedProducts$: Observable<TProductEntity[]> =
    this.storeFacade.recommendedProductsExceptSelected$.pipe(
      map(products => products?.slice(0, 4)),
    );

  public counter$: Observable<number> = this.selectedProducts$.pipe<number>(
    map(products => products?.length),
  );

  public viewFlags: CartViewFlags = this.viewService.getFullState('flags');

  ngOnInit(): void {
    this.subscribeToCounter();
  }

  public toggleCart(): void {
    if (this.isMobileScreen()) {
      this.viewService.toggleMobileCartOpen();
    } else {
      this.viewService.toggleCartOpen();
    }

    this.analyticsService.trackCartToggle(
      this.viewFlags.isMobileCartOpen(),
      this.viewFlags.isCartOpen(),
    );
  }

  public onStartClick(): void {
    this.analyticsService.trackStartButtonClick();
  }

  public onProductToggle(product: TProductEntity, toggle: boolean): void {
    this.storeFacade.toggleProduct(product);
    this.analyticsService.trackProductToggle(product, toggle);
  }

  private subscribeToCounter(): void {
    const initializationCount: number = 2;

    this.counter$
      .pipe(takeUntilDestroyed(this.destroyRef), pairwise(), skip(initializationCount))
      .subscribe(([previousValue, currentValue]: number[]) => {
        if (currentValue === 0) {
          this.viewService.toggleMobileCartOpen(false);
          this.viewService.toggleCartOpen(false);
        }

        if (previousValue < currentValue) {
          if (!this.isMobileScreen()) {
            this.viewService.toggleCartOpen(true);
          } else {
            this.viewService.toggleMobileCartOpen(true);
          }

          if (this.isMobileScreen() && this.isModalFormOpen()) {
            this.viewService.toggleMobileCartOpen(false);
          }
        }
      });
  }
}
