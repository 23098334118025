import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Provider, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIcon } from '@core/modules/components/icon/icon.component';
import { ResponsiveDirective } from '@core/modules/directives/responsive.directive';
import { IconComponent } from '@noda-lib/assets/components';
import { TranslateComponent } from '@noda-lib/translate';
import { CartProductCardComponent } from '@shared/cart-product-card/cart-product-card.component';
import { ShadowComponent } from '@shared/shadow/shadow.component';
import { AddLangPrefixPipe } from '@translate/pipes/add-lang-prefix.pipe';

import { CartViewService } from '../services/cart.view.service';

export const CartImports: Type<unknown>[] = [
  AsyncPipe,
  NgTemplateOutlet,
  CartProductCardComponent,
  SvgIcon,
  RouterModule,
  ResponsiveDirective,
  ShadowComponent,
  TranslateComponent,
  IconComponent,
  AddLangPrefixPipe,
];

export const CartProviders: Provider[] = [CartViewService];
