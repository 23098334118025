import { inject, Injectable } from '@angular/core';
import { GA_EVENT_PREFIX } from '@core/constants/google.constants';
import { EnCAEvents } from '@core/enums/cross-analytics-events.enum';
import { EnGAEventNames } from '@core/enums/google.enums';
import { TProductEntity } from '@core/utils/products/entities/product.entity';
import { GTMService, IGTMEvent } from '@noda-lib/analytics';
import { CrossAnalyticsService } from '@noda-lib/cross-analytics';
import { LanguageService } from '@translate/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class CartAnalyticsService {
  private gtmService: GTMService = inject(GTMService, { optional: true });
  private caService: CrossAnalyticsService = inject(CrossAnalyticsService, { optional: true });
  private languageService: LanguageService = inject(LanguageService);

  public trackCartToggle(isMobileCartOpen: boolean, isCartOpen: boolean): void {
    const eventName: EnGAEventNames =
      isMobileCartOpen || isCartOpen ? EnGAEventNames.CART_OPEN : EnGAEventNames.CART_CLOSE;

    const event: IGTMEvent = {
      event: eventName,
      eventCategory: 'User',
      eventAction: 'userEvent',
      customOptions: {
        action: isMobileCartOpen || isCartOpen ? 'OPEN_CART' : 'CLOSE_CART',
      },
    };

    this.gtmService?.addEvent(event, true);

    const caEventType: EnCAEvents =
      isMobileCartOpen || isCartOpen ? EnCAEvents.CART_OPEN : EnCAEvents.CART_CLOSE;

    this.caService?.post({
      eventType: caEventType,
      customData: {
        gaEvent: `${GA_EVENT_PREFIX}_${eventName}`,
        lang: this.languageService.activeLang,
      },
    });
  }

  public trackStartButtonClick(): void {
    const event: IGTMEvent = {
      event: EnGAEventNames.CLICK_START_USING_NODA,
      eventCategory: 'User',
      eventAction: 'userEvent',
      customOptions: {
        action: 'START_USING_NODA',
      },
    };

    this.gtmService?.addEvent(event, true);
  }

  public trackProductToggle(product: TProductEntity, toggle: boolean): void {
    const eventName: string = toggle
      ? EnGAEventNames.CART_REMOVE_PRODUCT
      : EnGAEventNames.CART_ADD_RECOMMENDED_PRODUCT;

    const event: IGTMEvent = {
      event: eventName,
      eventCategory: 'User',
      eventAction: 'userEvent',
      customOptions: {
        action: toggle ? 'REMOVE_FROM_CART' : 'ADD_TO_CART',
        label: product.name,
        value: product.id,
      },
    };

    this.gtmService?.addEvent(event, true);

    const caEventType: EnCAEvents = toggle
      ? EnCAEvents.CART_REMOVE_PRODUCT
      : EnCAEvents.CART_ADD_RECOMMENDED_PRODUCT;

    this.caService?.post({
      eventType: caEventType,
      customData: {
        productID: product.externalId,
        gaEvent: `${GA_EVENT_PREFIX}_${eventName}`,
        lang: this.languageService.activeLang,
      },
    });
  }
}
