import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { LinkService } from '@core/services/link.service';
import { navCardComponentImports } from '@layout/nav/card/nav-card.imports';
import { ISubItem } from '@layout/nav/interfaces/nav.type';
import { NODA_DOCS_LINK } from '@shared/constants/links';

@Component({
  selector: 'noda-nav-card',
  standalone: true,
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navCardComponentImports,
})
export class NavCardComponent {
  private document: Document = inject(DOCUMENT);
  private linkService: LinkService = inject(LinkService);

  @Input() public item: ISubItem;

  public linkClickHandler(url: string): void {
    if (url) {
      this.navigate(url);
    }
  }

  private addQueryForDocs(url: string): string {
    return url?.includes(NODA_DOCS_LINK) ? url + this.linkService.getQueryParams() : url;
  }

  private navigate(url: string): void {
    this.document.defaultView?.open(this.addQueryForDocs(url), '_blank');
  }
}
