import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageService } from '@translate/services/language.service';

@UntilDestroy()
@Directive({
  selector: '[nodaChangeLangAttr]',
  exportAs: 'nodaChangeLangAttr',
  standalone: true,
})
export class ChangeLangAttrDirective implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.languageSubscriber();
  }

  private languageSubscriber(): void {
    this.languageService.langChanges$.pipe(untilDestroyed(this)).subscribe((lang: string): void => {
      this.document.documentElement.lang = lang;
    });
  }
}
