import { Injectable, signal, WritableSignal } from '@angular/core';
import { BaseNavService } from '@core/services/base-nav-service';

@Injectable({
  providedIn: 'root',
})
export class NavService implements BaseNavService {
  public activeMenuItem: WritableSignal<string | null> = signal(null);
  public isMobileMenuOpen: WritableSignal<boolean> = signal(false);

  public setActiveMenuItem(item: string): void {
    this.activeMenuItem.set(item);
  }

  public updateMenuItem(item: string): void {
    if (item !== this.activeMenuItem()) {
      this.clear();
    }
  }

  public clear(): void {
    this.activeMenuItem.set(null);
  }
}
