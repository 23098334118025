import { APP_INITIALIZER, Provider } from '@angular/core';
import { ScrollRestorationService } from '@core/services/scroll-restoration.service';

export function provideScrollRestoration(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (scrollRestorationService: ScrollRestorationService) => (): void =>
        scrollRestorationService.init(),
      deps: [ScrollRestorationService],
      multi: true,
    },
  ];
}
