import { ChangeDetectionStrategy, Component } from '@angular/core';
import { snowflakes } from '@layout/footer/components/new-year-post-footer/new-year-post-footer.db';
import { NewYearPostFooterImports } from '@layout/footer/components/new-year-post-footer/new-year-post-footer.imports';
import { ISnowflake } from '@layout/types/layout.type';

@Component({
  selector: 'noda-new-year-post-footer',
  templateUrl: './new-year-post-footer.component.html',
  styleUrls: ['./new-year-post-footer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: NewYearPostFooterImports,
})
export class NewYearPostFooterComponent {
  public snowflakes: ISnowflake[] = snowflakes;
}
