<section class="grid-container">
  <ng-container [ngTemplateOutlet]="badges" />

  @for (item of footer; track item.title; let index = $index) {
    <noda-link-list
      class="cell__{{ index }}"
      [items]="item.items"
      [itemTemplate]="itemLinkTemplate"
      [title]="item.title"
      [titleDivider]="divider"
    />
  }

  <ng-container
    *nodaResponsiveIf="[minWidthScreen.LG]"
    [ngTemplateOutlet]="divider"
    [ngTemplateOutletContext]="{ section: 'contact' }"
  />

  <ng-container *nodaResponsiveIf="['(max-width: 1023.9px)']">
    <ng-container [ngTemplateOutlet]="contactAndSocial" />
  </ng-container>

  <ng-container *nodaResponsiveIf="[minWidthScreen.LG]">
    <div class="grid-columns-wrapper">
      <ng-container [ngTemplateOutlet]="contactAndSocial" />
    </div>
  </ng-container>

  <ng-container
    [ngTemplateOutlet]="divider"
    [ngTemplateOutletContext]="{ section: 'info' }"
  />

  <span class="info__rights text-3-v2">
    &copy;
    <span>{{ currentYear }}</span>
    <noda-translate [key]="'common.contact.all-rights'" />
  </span>

  <div class="info__partners">
    @for (award of awards(); track award.title) {
      <img
        [alt]="award.title"
        [src]="award.image | src: 'origin'"
      />
    }
  </div>

  <p class="info__text">
    <noda-translate [key]="'common.contact.naudapay-limited'" />
  </p>

  <noda-link-list
    class="info__links row"
    [items]="additionalData"
    [itemTemplate]="infoItemTemplate"
  />
</section>
<noda-new-year-post-footer nodaNewYearTheme />

<ng-template #contactAndSocial>
  <section class="contact-us cell__5">
    <ng-container
      *nodaResponsiveIf="[minWidthScreen.LG]"
      [ngTemplateOutlet]="contactUs"
      [ngTemplateOutletContext]="{ isRow: true }"
    />

    <ng-container
      *nodaResponsiveIf="['(max-width: 1023px)']"
      [ngTemplateOutlet]="contactUs"
      [ngTemplateOutletContext]="{ isRow: false }"
    />

    <ng-template
      #contactUs
      let-isRow="isRow"
    >
      <noda-link-list
        [class.row]="isRow"
        [items]="contactData.items"
        [itemTemplate]="sign"
        [title]="contactData.title"
        [titleDivider]="!isRow && divider"
        [titleLink]="contactData.titleLink"
      />
    </ng-template>

    <ng-template
      #sign
      let-itemLink="itemLink"
    >
      <p class="sign">&#64;</p>
      <p><noda-translate [key]="itemLink.text" /></p>
    </ng-template>
  </section>

  <section class="social">
    <a
      href="https://www.linkedin.com/company/noda/"
      rel="nofollow"
      target="_blank"
      class="linkedin-text"
    >
      <p class="text-3-v2 text-3-v2--hover">
        <b><noda-translate [key]="'common.contact.follow'" /></b>
      </p>
    </a>
    <a
      href="https://www.linkedin.com/company/noda/"
      rel="nofollow"
      target="_blank"
      class="linkedin-icon"
    >
      <img
        alt="linkedin-icon"
        class="linkedin-link__icon"
        [src]="'footer/linkedin.svg' | src: 'assets'"
      />
    </a>
    <noda-button-v2
      class="social__button"
      [btnSize]="'s'"
      [icon]="{ position: 'right', name: 'arrow_drop_right' }"
      [isResizable]="false"
      [link]="NODA_FORM_ROUTER_LINK"
      [targetMethod]="'_self'"
      [text]="'common.call-to-action.contact-btn'"
    />
  </section>
</ng-template>

<ng-template
  #infoItemTemplate
  let-itemLink="itemLink"
>
  <b>
    <noda-translate
      class="info__links-item"
      [key]="itemLink.text"
    />
  </b>
</ng-template>

<ng-template #badges>
  <div class="badges-container">
    <a
      class="noda-logo"
      [queryParamsHandling]="'merge'"
      [routerLink]="'/' | addLangPrefix | async"
    >
      <svg-icon
        name="footer/logo.svg#logo"
        class="icon"
      />
      <div
        #newYearTheme="newYearThemeDirective"
        nodaNewYearTheme
      >
        @if(newYearTheme.isNewYearTheme) {
          <img
            alt="new-year-ball"
            class="new-year-ball"
            [src]="'new-year-theme/ball-footer.svg' | src : 'assets'"
          />
        }
      </div>
    </a>
    <a
      href="https://www.capterra.com/p/10013489/Noda/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
      rel="nofollow"
      target="_blank"
      class="badges-container__capterra"
    >
      <img
        alt="capterra"
        src="https://assets.capterra.com/badge/ccfcbdf02be10be8b223ea79ba6af37c.svg?v=2299284&p=351938"
      />
    </a>
  </div>
</ng-template>

<ng-template
  #divider
  let-section="section"
>
  <span class="divider-line {{ section }}"></span>
</ng-template>

<ng-template
  #itemLinkTemplate
  let-itemLink="itemLink"
>
  <noda-translate [key]="itemLink.text" />
  @if (itemLink.text?.includes('login-to-hub')) {
    <img
      alt="hub.svg"
      class="item-link-icon"
      [src]="'footer/hub.svg' | src: 'origin'"
    />
  }
  @if (itemLink?.text.includes('tech-documentation')) {
    <svg-icon
      name="shared/open_in_new.svg#open_in_new"
      class="item-link-icon"
      [size]="16"
    />
  }
</ng-template>
