<div class="mobile-wrapper {{ themeService.currentThemeMode$ | async }}">
  <noda-mobile-menu-items [mobileNavList]="mobileNavList()" />
  @if (switcherAvailable()) {
  <noda-language-switcher
    *nodaResponsiveIf="['(max-width: 468.9px)']"
    class="lang-switcher--mobile"
  />
  }
  <noda-mobile-menu-footer [footerData]="footerData()" />
</div>
