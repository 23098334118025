import { FactoryProvider, Provider, SkipSelf } from '@angular/core';
import { SrcPipe } from '@core/modules/pipes/src.pipe';
import { TUI_ICON_RESOLVER } from '@taiga-ui/core';

function formatTuiIconName(name: string): string {
  return name?.replace('@tui.', '');
}

const iconsProvider: Provider = {
  provide: TUI_ICON_RESOLVER,
  deps: [[new SkipSelf(), TUI_ICON_RESOLVER]],
  useFactory: () => {
    const srcPipe: SrcPipe = new SrcPipe();

    return (name: string) =>
      name.startsWith('@tui.')
        ? srcPipe.transform(`icons/${formatTuiIconName(name)}.svg`, 'tui')
        : srcPipe.transform(`icons/${name}.svg`, 'assets');
  },
};

export const provideCustomIcons = (): FactoryProvider => iconsProvider;
