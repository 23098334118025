<div class="submenu">
  <noda-nav-menu-item
    [isDesktop]="true"
    [label]="mainLabel()"
    [labelTemplate]="mainLabelTemplate()"
  />

  <div
    *nodaAccordionToggle="isActive()"
    class="submenu-items"
  >
    @for (child of filteredItems(); track $index) {
      @if (child.params) {
        <li
          routerLinkActive="active-nav-item"
          class="nav-item"
          [queryParams]="child.params"
          [queryParamsHandling]="'merge'"
          [routerLink]="child.link | addLangPrefix | async"
        >
          <noda-translate [key]="child.label" />
        </li>
      } @else {
        <li
          routerLinkActive="active-nav-item"
          class="nav-item"
          [queryParamsHandling]="'merge'"
          [routerLink]="child.link | addLangPrefix | async"
        >
          <noda-translate [key]="child.label" />
        </li>
      }
    }
  </div>
</div>
