import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  InputSignal,
  Signal,
  TemplateRef,
} from '@angular/core';
import { subMenuComponentImports } from '@layout/nav/components/sub-menu/sub-menu.imports';
import { IMenuItem } from '@layout/nav/interfaces/nav.type';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ArticlesDataService } from '@pages/articles/services/articles.data.service';

@UntilDestroy()
@Component({
  selector: 'noda-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: subMenuComponentImports,
})
export class SubMenuComponent {
  private articlesDataService: ArticlesDataService = inject(ArticlesDataService);

  public activeMenuItem: InputSignal<string> = input<string>();
  public mainLabel: InputSignal<string> = input<string>();
  public mainLabelTemplate: InputSignal<TemplateRef<HTMLElement>> =
    input<TemplateRef<HTMLElement>>();
  public isActive: Signal<boolean> = computed(() => this.activeMenuItem() === this.mainLabel());
  public items: InputSignal<IMenuItem[]> = input.required<IMenuItem[]>();

  public filteredItems: Signal<IMenuItem[]> = computed(() => {
    const allItems: IMenuItem[] = this.items();

    const availableTagValues: string[] = this.articlesDataService
      .currentTagsForNavigation()
      .map(tag => tag.value);

    return allItems.filter((item: IMenuItem) => {
      if (!item.params) {
        return true;
      }

      return item.params['tag'] && availableTagValues.includes(item.params['tag']);
    });
  });
}
