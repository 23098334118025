import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { EnCAEvents } from '@core/cross-analytics/enums/cross-analytics.enum';
import { CrossAnalyticsService } from '@noda-lib/cross-analytics';
import { AVAILABLE_LANGS } from '@translate/constants/available-langs';
import { Observable, of } from 'rxjs';

import { LanguageService } from '../../services/language.service';
import { LanguageRoutingService } from '../../services/language-roating.service';
import { imports } from './imports';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'noda-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  imports: imports,
})
export class LanguageSwitcherComponent {
  public availableLangs: string[] = AVAILABLE_LANGS;

  constructor(
    public service: LanguageService,
    private routingService: LanguageRoutingService,
    @Optional() private caService: CrossAnalyticsService,
  ) {}

  public trackByLang(_: number, lang: string): string {
    return lang;
  }

  public changeLang(lang: string): void {
    const activeLang: string = this.service.activeLang;

    if (lang === activeLang) {
      return;
    }

    this.service.switch(lang);

    const caEventStream$: Observable<void> = this.caService
      ? this.caService.post({
          eventType: EnCAEvents.LANGUAGE_CHANGED,
          customData: { to: lang, from: activeLang, lang },
        })
      : of(void null);

    caEventStream$.subscribe(() => {
      this.routingService.updateUrlForActiveLang();
    });
  }

  public getCountryFromLangCode(lang: string): string {
    const matches: RegExpMatchArray = lang.match(/\w{2}[-_](\w{2})/i);

    return matches ? matches[1] : lang;
  }
}
