import {
  animate,
  AnimationTriggerMetadata,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

type TAnimationStyles = Record<string, number | string>;

const animationTime: string = '375ms';

const stylesEnter: TAnimationStyles = {
  right: '-100%',
};

const animationEnter: TAnimationStyles = {
  right: '0',
};

const animationLeave: TAnimationStyles = {
  transform: 'translateX(-100%)',
  right: '-100%',
};

export const cartAnimations: AnimationTriggerMetadata[] = [
  trigger('toggleButton', [
    transition(':enter', [
      style(stylesEnter),
      animate(`${animationTime} ease-out`, style(animationEnter)),
    ]),
    transition(':leave', [
      animate(`${animationTime} ease-in`, style(animationLeave)),
    ]),
  ]),
  trigger('toggleCart', [
    transition(':enter', [
      query('.cart-wrap', [
        animate('0ms', style({ visibility: 'hidden' })),
      ]),
      style(stylesEnter),
      animate(`${animationTime} ease-out`, style(animationEnter)),
    ]),
    transition(':leave', [
      animate(`${animationTime} ease-in`, style(animationLeave)),
    ]),
  ]),
];
