import { HttpRequest } from '@angular/common/http';
import { EnvironmentProviders, inject } from '@angular/core';
import { AB_TEST_ID } from '@core/api/constants/ab-test.constants';
import { EnApi } from '@core/api/enums/api.enums';
import { EnvMergeService } from '@core/services/env-merge.service';
import { environment } from '@env/environment';
import { IEnvironment } from '@env/interfaces/environment.interface';
import { ABTestingService } from '@noda-lib/analytics/ab-testing';
import { CrossAnalyticsService, ICAEnvironment, toHttpHeaders } from '@noda-lib/cross-analytics';
import {
  provideUtilsInitializer,
  provideUtilsInterceptor,
  TApiHeaders,
  TApiHeadersHandler,
  TApiInitializeHandler,
  TApiTagsMap,
  withHeadersHandler,
  withInitializeHandler,
  withTagsUrlsMap,
} from '@noda-lib/utils/api';
import { ISimple } from '@noda-lib/utils/interfaces';
import { REQUEST } from '@noda-lib/utils/ssr';
import { ArticlesDataService } from '@pages/articles/services/articles.data.service';
import { EventsService } from '@pages/events/services/events.service';
import { Request } from 'express';

function initializeFactoryHandler(): TApiInitializeHandler<any> {
  const eventService: EventsService = inject(EventsService);
  const caService: CrossAnalyticsService = inject(CrossAnalyticsService, { optional: true });
  const abTestingService: ABTestingService = inject(ABTestingService);
  const mergeEnvService: EnvMergeService = inject(EnvMergeService);
  const articlesDataService: ArticlesDataService = inject(ArticlesDataService);

  return (env: IEnvironment) => {
    mergeEnvService.mergeEnvironment(env);
    if (caService) {
      caService.init(env.APP_LOGGER_SERVICE, env as ICAEnvironment);
    }
    abTestingService.init(AB_TEST_ID);

    eventService.getActiveEvents().subscribe();
    articlesDataService.fetchTags();
  };
}

function tagsMapFactory(): TApiTagsMap {
  return {
    get [EnApi.CMS_API_TAG](): string {
      return environment.APP_STRAPI_HOST;
    },
    get [EnApi.SALESKIT_API_TAG](): string {
      return environment.SALESKIT_HOST;
    },
    get [EnApi.MARKETPLACE_API_TAG](): string {
      return environment.APP_MARKETPLACE_API_HOST;
    },
    get [EnApi.LANDING_API_TAG](): string {
      return environment.APP_LANDING_API_HOST;
    },
  };
}

function headerFactoryHandler(): TApiHeadersHandler {
  return (req: HttpRequest<unknown>): TApiHeaders => {
    const headers: ISimple<string> = {};

    const request: Request = inject(REQUEST, { optional: true });

    if (request?.headers['x-forwarded-for']) {
      headers['x-forwarded-for'] = request.headers['x-forwarded-for'] as string;
    }

    if (req.url.startsWith(EnApi.CMS_API_TAG)) {
      headers['Authorization'] = `Bearer ${environment.APP_STRAPI_TOKEN}`;
    }

    if (
      req.url.startsWith(EnApi.LANDING_API_TAG) ||
      req.url.startsWith(EnApi.MARKETPLACE_API_TAG)
    ) {
      const caService: CrossAnalyticsService = inject(CrossAnalyticsService, { optional: true });

      if (caService?.getUserInfo()) {
        Object.entries(toHttpHeaders(caService.getUserInfo())).forEach(([key, value]) => {
          headers[key] = value;
        });
      }
    }

    return { headers };
  };
}

export function provideUtilsApi(): EnvironmentProviders[] {
  return [
    provideUtilsInitializer(environment, withInitializeHandler(initializeFactoryHandler)),
    provideUtilsInterceptor(
      withTagsUrlsMap(tagsMapFactory),
      withHeadersHandler(headerFactoryHandler, 'factory'),
    ),
  ];
}
