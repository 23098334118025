<li
  routerLinkActive="active-nav-item"
  class="nav-item"
  [class.active]="isActiveLink()"
  [queryParamsHandling]="'merge'"
  [routerLink]="link() | addLangPrefix | async"
  (click)="utmService.openHrefWithUtmParameters(href(), isSelfTarget())"
>
  <ng-content select="[content]" />
</li>
