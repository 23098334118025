/* eslint @/brace-style: "off" */
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MARKETPLACE_STORE, MarketplaceStore } from '@core/store/marketplace.store';
import { MarketplaceStoreFacade } from '@core/store/services/marketplace.store.facade';
import { TLoadProductsPayload } from '@core/types/marketplace.types';
import { TProductEntity } from '@core/utils/products/entities/product.entity';
import { TranslateService } from '@noda-lib/translate';
import { ISimple } from '@noda-lib/utils/interfaces';
import {
  ExtendsFactory,
  OnInitActionsListener,
  OnInitErrorsListener,
  ServiceManager,
  TOnlyUnsubscribe,
} from '@noda-lib/utils/managers';
import { RxStore } from '@noda-lib/utils/stores';
import { DEFAULT_LANG, TranslateLang } from '@translate/constants/available-langs';
import { filter, Subscription } from 'rxjs';

import { MarketplaceDataService } from './marketplace.data.service';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceViewService
  extends ExtendsFactory(ServiceManager<TOnlyUnsubscribe>(['unsubscribe']))
  implements OnInitActionsListener, OnInitErrorsListener, OnDestroy
{
  private dataService: MarketplaceDataService = inject(MarketplaceDataService);
  private translateService: TranslateService = inject(TranslateService);
  private router: Router = inject(Router);
  private storeService: RxStore<MarketplaceStore> = inject(MARKETPLACE_STORE);
  private storeFacade: MarketplaceStoreFacade = inject(MarketplaceStoreFacade);
  private location: Location = inject(Location);

  private selectedIdsSubscription: Subscription;

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public initActionsListener(): void {
    this.dataService
      .listenActions()
      .pipe(this.untilDestroyed())
      .subscribe(action => {
        switch (action.type) {
          case 'load-products':
            this.afterLoadProducts(action.payload);
            break;
          case 'load-recommended':
            this.afterLoadRecommended(action.payload);
            break;
        }
      });
  }

  public initErrorsListener(): void {
    this.dataService
      .listenErrors()
      .pipe(this.untilDestroyed())
      .subscribe(error => {
        switch (error.type) {
          case 'error-load-products':
          case 'error-load-recommended':
            this.afterError(error.payload);
            break;
          default:
            break;
        }
      });
  }

  public initLanguageChangeListener(): void {
    this.translateService
      .langChanges()
      .pipe(this.untilDestroyed())
      .subscribe(() => {
        this.loadProducts();
      });
  }

  private loadProducts(
    lang: TranslateLang = this.translateService.getActiveLang() as TranslateLang,
  ): void {
    const productsRecord: Partial<ISimple<TProductEntity[]>> =
      this.storeService.getValue('products');

    const needsDefaultProducts: boolean = lang !== DEFAULT_LANG && !productsRecord?.[DEFAULT_LANG];

    if (needsDefaultProducts) {
      this.dataService.loadProducts(DEFAULT_LANG);
    }

    if (productsRecord?.[lang]) {
      return;
    }

    this.dataService.loadProducts(lang);
  }

  private afterLoadProducts(payload: TLoadProductsPayload): void {
    const lang: TranslateLang = payload.lang;

    this.storeFacade.patchValue('products', { [lang]: payload.products });
    this.storeFacade.patchValue('productPackages', { [lang]: payload.packages });
    this.storeFacade.patchValue('viewCategories', { [lang]: payload.viewCategories });
    this.storeFacade.patchValue('labels', { [lang]: payload.labels });

    const queryParams: HttpParams = new HttpParams({
      fromString: this.location.path().split('?')[1],
    });
    const selectedProductsIds: string[] = queryParams.get('cart')?.split(',') || [];

    this.storeService.setValue('selectedProductsIds', selectedProductsIds);

    this.initSelectedIdsListener();
  }

  private initSelectedIdsListener(): void {
    if (this.selectedIdsSubscription) {
      return;
    }

    this.selectedIdsSubscription = this.storeService
      .select('selectedProductsIds')
      .pipe(filter(Boolean), this.untilDestroyed())
      .subscribe((ids: string[]) => {
        const [currentRouteString, queryParamsString]: string[] = this.location.path().split('?');
        const currentRoute: string[] = currentRouteString.split('/').filter(Boolean);
        const queryParams: Params = Object.fromEntries(new URLSearchParams(queryParamsString));

        if (ids?.length) {
          queryParams['cart'] = ids.join(',');
        } else {
          delete queryParams['cart'];
        }

        // for some reason after adding hydration to the project,
        // the router doesn't merge queryParams after first navigation
        // probably because activatedRoute is not presented correctly
        this.router.navigate(currentRoute, {
          queryParams,
          replaceUrl: true,
        });
      });
  }

  public loadRecommended(): void {
    this.dataService.loadRecommended();
  }

  private afterLoadRecommended(payload: string[]): void {
    this.storeService.setValue('recommendedProductsIds', payload);
  }

  private afterError(_payload: any): void {
    this.storeService.reset();
    this.router.navigate(['contact-sales']);
  }
}
