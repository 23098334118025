import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  InputSignal,
  Signal,
  TemplateRef,
} from '@angular/core';
import { LinkService } from '@core/services/link.service';
import { UtmParametersService } from '@core/services/utm-parameters.service';
import { Item } from '@layout/types/layout.type';
import { Bind } from '@noda-lib/utils/decorators';
import { ArticlesDataService } from '@pages/articles/services/articles.data.service';
import { LinkListImports } from '@shared/link-list/link-list.imports';

@Component({
  standalone: true,
  selector: 'noda-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: LinkListImports,
})
export class LinkListComponent {
  private articlesDataService: ArticlesDataService = inject(ArticlesDataService);
  private utmService: UtmParametersService = inject(UtmParametersService);
  private linkService: LinkService = inject(LinkService);

  public title: InputSignal<string> = input<string>();
  public titleLink: InputSignal<string> = input<string>();
  public items: InputSignal<Item[]> = input<Item[]>([]);
  public titleDivider: InputSignal<TemplateRef<any>> = input<TemplateRef<any>>();
  public itemTemplate: InputSignal<TemplateRef<any>> = input<TemplateRef<any>>();
  public titleTemplate: InputSignal<TemplateRef<any>> = input<TemplateRef<any>>();

  public filteredItems: Signal<Item[]> = computed(() => {
    const allItems: Item[] = this.items();
    const availableTagValues: string[] = this.articlesDataService
      .currentTagsForNavigation()
      .map(tag => tag.value);

    return allItems.filter((item: Item) => {
      if (!item.params) {
        return true;
      }

      return item.params['tag'] && availableTagValues.includes(item.params['tag']);
    });
  });

  @Bind()
  public addParamsToUrl(item: Item): string {
    const link: string = item?.staticQuerySaved
      ? item.link + this.linkService.getQueryParams()
      : item.link;

    return this.utmService.addUtmParamsToUrl(link);
  }
}
