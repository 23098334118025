import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  InputSignal,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  additionalData,
  contactData,
  footerAwardsDark,
  footerAwardsWhite,
  footerData,
} from '@layout/footer/footer.db';
import { FooterImports } from '@layout/footer/footer.imports';
import { IAward, IFooterTypes, Item } from '@layout/types/layout.type';
import { NODA_FORM_ROUTER_LINK } from '@shared/constants/links';
import { EnMinWidthScreen } from '@shared/constants/screen-breakpoints';

@Component({
  standalone: true,
  selector: 'noda-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: FooterImports,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public readonly contactData: IFooterTypes = contactData;
  public readonly additionalData: Item[] = additionalData;
  public readonly awards: Signal<IAward[]> = computed((): IAward[] =>
    this.darkModeEnable() ? footerAwardsDark : footerAwardsWhite,
  );
  public readonly NODA_FORM_ROUTER_LINK: string = NODA_FORM_ROUTER_LINK;
  public minWidthScreen: typeof EnMinWidthScreen = EnMinWidthScreen;
  public footer: IFooterTypes[] = footerData;
  public currentYear: number = new Date().getFullYear();
  public darkModeEnable: InputSignal<boolean> = input(false);

  @HostBinding('class.dark-mode') private get darkMode(): boolean {
    return this.darkModeEnable();
  }
  @HostBinding('class.white-mode') private get whiteMode(): boolean {
    return !this.darkModeEnable();
  }

  @ViewChild('divider', { static: true })
  public divider: TemplateRef<HTMLElement>;
}
