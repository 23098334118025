import { ChangeDetectorRef, Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ROUTES_WITH_DISABLED_LANG_SWITCHER } from '@core/api/constants/not-translated-pages.constants';
import { RoutingService } from '@core/services/routing.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[nodaHideLangSwitcher]',
  exportAs: 'hideLangSwitcher',
  standalone: true,
})
export class HideLangSwitcherDirective implements OnInit {
  public showSwitcher: boolean;

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private routingService: RoutingService,
  ) {}

  ngOnInit(): void {
    this.routingServiceSubscriber();
  }

  public routingServiceSubscriber(): void {
    this.routingService.getCurrentRoute$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((currentRoute: string) => {
        this.updateView(currentRoute);
      });
  }

  private updateView(currentRoute: string): void {
    this.viewContainer.clear();
    if (this.isShowSwitcher(currentRoute)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    this.changeDetectorRef.markForCheck();
  }

  private isShowSwitcher(currentRoute: string): boolean {
    return !ROUTES_WITH_DISABLED_LANG_SWITCHER.some((route: string) =>
      currentRoute.includes(route),
    );
  }
}
