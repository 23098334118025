/* eslint @/brace-style: "off" */
import { inject, Injectable, OnDestroy } from '@angular/core';
import { IRecommendedProducts } from '@core/interfaces/recommended-products.interface';
import { IStrapiLocalizedData } from '@core/interfaces/strapi.interface';
import { MarketplaceApiService } from '@core/services/marketplace/marketplace.api.service';
import { TMarketplaceAction } from '@core/types/marketplace.types';
import { transformStrapiProductsToEntities } from '@core/utils/products/dto-to-entity';
import { TProductDto } from '@core/utils/products/dtos/product.dto';
import { TProductLabelDto } from '@core/utils/products/dtos/product-label.dto';
import { TProductPackageDto } from '@core/utils/products/dtos/product-package.dto';
import { TViewCategoryDto } from '@core/utils/products/dtos/product-view-category.dto';
import {
  ExtendsFactory,
  ServiceAction,
  ServiceManager,
  UseManagers,
} from '@noda-lib/utils/managers';
import { TranslateLang } from '@translate/constants/available-langs';
import { forkJoin } from 'rxjs';

const MarketplaceDataManagers = UseManagers(['actions', 'error', 'unsubscribe']);

@Injectable({
  providedIn: 'root',
})
export class MarketplaceDataService
  extends ExtendsFactory(
    ServiceManager<typeof MarketplaceDataManagers, TMarketplaceAction, MarketplaceDataService>(
      MarketplaceDataManagers,
    ),
  )
  implements OnDestroy
{
  private apiService: MarketplaceApiService = inject(MarketplaceApiService);

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  // todo: add loading flag
  public loadProducts(locale: TranslateLang): void {
    const next = ([
      products,
      packages,
      viewCategories,
      labels,
    ]: [
      IStrapiLocalizedData<TProductDto>,
      IStrapiLocalizedData<TProductPackageDto>,
      IStrapiLocalizedData<TViewCategoryDto>,
      IStrapiLocalizedData<TProductLabelDto>,
    ]): void => {
      this.action(
        new ServiceAction('load-products', {
          lang: locale,
          products: transformStrapiProductsToEntities(products),
          packages: packages.data.map(({ attributes }) => ({
            label: attributes.label,
            type: attributes.type,
          })),
          viewCategories: viewCategories.data.map(({ attributes }) => ({
            label: attributes.label,
            type: attributes.type,
            description: attributes.description,
          })),
          labels: labels.data.map(({ attributes }) => ({
            label: attributes.label,
            type: attributes.type,
          })),
        }),
      );
    };

    const error = (): void => {};

    forkJoin([
      this.apiService.getProducts$({ locale }),
      this.apiService.getProductPackages$({ locale }),
      this.apiService.getViewCategories$({ locale }),
      this.apiService.getLabels$({ locale }),
    ])
      .pipe(this.untilDestroyed())
      .subscribe({ next, error: () => this.onError('error-load-products', error) });
  }

  // todo: add loading flag
  public loadRecommended(): void {
    const next = (recommended: IRecommendedProducts): void => {
      const ids: string[] = recommended.scores.map(({ externalId }) => externalId);
      this.action(new ServiceAction('load-recommended', ids));
    };

    const error = (): void => {};

    this.apiService
      .getRecommendedProducts$()
      .pipe(this.untilDestroyed())
      .subscribe({ next, error: () => this.onError('error-load-recommended', error) });
  }
}
