<a
  nodaManageLinkRel
  class="item"
  [class.multiple]="item.children"
  [elementLink]="item.href"
  (click)="linkClickHandler(item.href)"
>
  <div class="item-icon">
    <svg-icon
      name="nav/{{ item.icon }}.svg#{{ item.icon }}"
      [size]="24"
    />
  </div>

  <summary class="item-content">
    <h4 class="item-subtitle">
      <span class="content-subtitle h4">
        <noda-translate [key]="item.subtitle" />
        @if (item.href) {
        <svg-icon
          name="nav/{{ item.subIcon }}.svg#{{ item.subIcon }}"
          [size]="24"
        />
        }
      </span>
    </h4>
    <p
      *ngIf="item.subtext"
      class="item-subtext"
    >
      <noda-translate [key]="item.subtext" />
    </p>
    <p class="item-subchildren">
      @for (child of item.children; track $index) { @if (child.href) {
      <a
        nodaManageLinkRel
        nodaStopPropagation
        class="link-subtext"
        [elementLink]="child.href"
        (click)="linkClickHandler(child.href)"
      >
        <noda-translate [key]="child.subtitle" />
      </a>
      } @else {
      <a
        nodaStopPropagation
        class="link-subtext"
        [queryParamsHandling]="'merge'"
        [routerLink]="child.link | addLangPrefix | async"
      >
        <noda-translate [key]="child.subtitle" />
      </a>
      } }
    </p>
  </summary>
</a>
