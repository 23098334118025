import { IStrapiData } from '@core/interfaces/strapi.interface';

import { TProductDto } from './dtos/product.dto';
import { TProductEntity } from './entities/product.entity';

export function transformProductDtoToEntity(entity: TProductDto, id: number): TProductEntity {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { categories, labels, logo, packages, viewCategory, ...rest }: TProductDto = entity;

  return {
    ...rest,
    packages: packages.data.map(item => ({
      label: item.attributes.label,
      type: item.attributes.type,
    })),
    viewCategory: viewCategory?.data?.attributes?.type,
    labels: labels.data.map(label => label.attributes.type),
    logo: logo.data?.attributes?.url,
    id,
  };
}

export function transformStrapiProductsToEntities(
  data: IStrapiData<TProductDto>,
): TProductEntity[] {
  return data.data.map(({ attributes, id }) => transformProductDtoToEntity(attributes, id));
}
