import { EnvironmentProviders, importProvidersFrom, Injectable, Provider } from '@angular/core';
import { environment } from '@env/environment';
import {
  INodaMonitoringEnvsLoaderService,
  MONITORING_ENVS_LOADER_TOKEN,
  NodaMonitoringModule,
} from '@noda/monitoring';
import { INodaMonitoringEnvsConfig } from '@noda/monitoring/lib/core/interfaces/envs-config.interface';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';

@Injectable()
export class MonitoringEnvsLoader implements INodaMonitoringEnvsLoaderService {
  public getEnvs(): Observable<INodaMonitoringEnvsConfig> {
    const url: string = environment.CONFIG_ROOT;

    return ajax.getJSON<INodaMonitoringEnvsConfig>(url);
  }
}

export function provideMonitoring(): (EnvironmentProviders | Provider)[] {
  return [
    importProvidersFrom(NodaMonitoringModule.forRoot({ production: environment.production })),
    {
      provide: MONITORING_ENVS_LOADER_TOKEN,
      useClass: MonitoringEnvsLoader,
    },
  ];
}
