import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  ImportProvidersSource,
  mergeApplicationConfig,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  UrlSerializer,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { TranslateDB } from '@app-i18n';
import { provideUtilsApi } from '@core/api/handlers/api.handlers';
import { provideCA } from '@core/handlers/cross-analytics.handlers';
import { provideAnalytics } from '@core/providers/analytics.provider';
import { provideAssets } from '@core/providers/assets.provider';
import { provideMonitoring } from '@core/providers/monitoring.provider';
import { provideScrollRestoration } from '@core/providers/scroll-restoration.provider';
import { UrlNormalizerService } from '@core/services/url-normalizer.service';
import { provideChildStore } from '@core/store/providers/store.provider';
import { environment } from '@env/environment';
import { FooterThemeService } from '@layout/services/footer-theme.service';
import { provideABTesting } from '@noda-lib/analytics/ab-testing';
import { AssetsRootModule } from '@noda-lib/assets/root';
import { provideTranslate } from '@noda-lib/translate';
import { provideUtilsCookies } from '@noda-lib/utils/cookies';
import { withSsrCookies } from '@noda-lib/utils/ssr';
import { tuiAssetsPathProvider } from '@taiga-ui/core';
import { AVAILABLE_LANGS, DEFAULT_LANG } from '@translate/constants/available-langs';

import { routes } from './app.routes';

const clientOnlyConfig: ApplicationConfig = {
  providers: [
    provideMonitoring(),
    provideCA(),
    provideAnalytics(),
  ],
};

const scrollConfig: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
};

const imports: ImportProvidersSource[] = [
  AssetsRootModule,
];

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    // TODO: causes bugs, needs investigation and full regression WEB-1371
    // provideClientHydration(withEventReplay()),
    {
      provide: UrlSerializer,
      useClass: UrlNormalizerService,
    },
    provideRouter(routes, inMemoryScrollingFeature, withEnabledBlockingInitialNavigation()),
    provideScrollRestoration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAnimations(),
    provideUtilsApi(),
    provideChildStore(),
    tuiAssetsPathProvider(`${environment.ASSETS_PATH}/taiga-ui/icons`),
    provideAssets(),
    importProvidersFrom(imports),
    provideABTesting({ ssrEnabled: true }),
    provideUtilsCookies(withSsrCookies()),
    provideTranslate({
      db: TranslateDB,
      fallbackLang: DEFAULT_LANG,
      availableLangs: AVAILABLE_LANGS,
    }),
    FooterThemeService,
  ],
};

export const config = mergeApplicationConfig(appConfig, clientOnlyConfig);
