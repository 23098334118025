import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  InputSignal,
  model,
  ModelSignal,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { PRODUCT_PAGES } from '@core/api/constants/pages.constants';
import { isPathAvailableWithTranslations } from '@core/handlers/utils.handlers';
import { BaseNavService } from '@core/services/base-nav-service';
import { LinkService } from '@core/services/link.service';
import { RoutingService } from '@core/services/routing.service';
import { navMenuItemComponentImports } from '@layout/nav/components/menu-item/nav-menu-item.imports';
import {
  availableDeveloperRoutes,
  availableMoreRoutes,
  availableResourcesRoutes,
} from '@layout/nav/db/header.db';
import { EnMenuItemType } from '@layout/nav/enums/nav.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISimple } from '@noda-lib/utils/interfaces';
import { parsePath } from '@translate/utils/parse-lang-path';

@UntilDestroy()
@Component({
  selector: 'noda-nav-menu-item',
  standalone: true,
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navMenuItemComponentImports,
})
export class NavMenuItemComponent implements OnInit {
  public isDesktop: InputSignal<boolean> = input<boolean>();
  public isSelfTarget: InputSignal<boolean> = input<boolean>();
  public label: InputSignal<string> = input<string>();
  public labelTemplate: InputSignal<TemplateRef<HTMLElement>> = input<TemplateRef<HTMLElement>>();
  public link: InputSignal<string> = input<string>();
  public href: InputSignal<string> = input<string>();
  public isActive: ModelSignal<boolean> = model<boolean>();

  private linkService: LinkService = inject(LinkService);

  constructor(
    public navService: BaseNavService,
    public routingService: RoutingService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public get isActiveLink(): boolean {
    return (
      (this.isDesktop() && this.navService.activeMenuItem() === this.label()) || this.isActive()
    );
  }

  ngOnInit(): void {
    this.isActive.set(this.getIsActiveLink(this.routingService.getCurrentRoute()));

    this.routingService.getCurrentRoute$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isActive.set(this.getIsActiveLink(value));
      this.navService.clear();
      this.changeDetectorRef.markForCheck();
    });
  }

  public onMouseEnter(): void {
    this.navService.setActiveMenuItem(this.label());
  }

  public onMouseLeave(): void {
    this.navService.updateMenuItem(this.label());
  }

  private getIsActiveLink(path: string): boolean {
    const { cleanPath, parentRoute } = this.parseLink(path);

    const labelConditions: ISimple<boolean> = {
      [EnMenuItemType.About]: parentRoute === 'about',
      [EnMenuItemType.Developers]: availableDeveloperRoutes.includes(`/${parentRoute}`),
      [EnMenuItemType.Products]: isPathAvailableWithTranslations(PRODUCT_PAGES, cleanPath),
      [EnMenuItemType.Resources]: availableResourcesRoutes.includes(`/${parentRoute}`),
      [EnMenuItemType.UseCases]: parentRoute === 'use-cases',
    };

    const isMore: boolean =
      !this.label() && this.labelTemplate() && availableMoreRoutes.includes(`/${parentRoute}`);

    return this.isDesktop && (labelConditions[this.label()] || isMore);
  }

  private parseLink(link: string): { cleanPath: string; parentRoute: string } {
    const { cleanPath: path, parentRoute: route } = parsePath(link);

    const cleanPath: string = this.removeQueryParams(path);
    const parentRoute: string = this.removeQueryParams(route);

    return { cleanPath, parentRoute };
  }

  private removeQueryParams(value: string): string {
    return value?.replace(this.linkService.getQueryParams(), '');
  }
}
