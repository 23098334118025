import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@env/environment';

export const productionGuard: CanActivateFn = (): boolean => {
  const router: Router = inject(Router);

  if (environment.production) {
    router.navigate(['/404']);

    return false;
  }

  return true;
};
