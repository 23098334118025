<div
  class="mobile-menu-item"
  (click)="toggle.emit()"
>
  <div class="mobile-menu-label">
    <ng-container *ngIf="menuItem().link">
      <a
        [queryParamsHandling]="'merge'"
        [routerLink]="menuItem().link | addLangPrefix | async"
      >
        <noda-nav-menu-item
          class="item"
          [isActive]="isActive()"
          [isDesktop]="false"
          [label]="menuItem().label"
        />
      </a>
    </ng-container>
    <ng-container *ngIf="menuItem().href">
      <a
        [href]="menuItem().href | map : addParamsToUrl"
        [target]="menuItem().isSelfTarget ? '_self' : '_blank'"
      >
        <noda-nav-menu-item
          class="item"
          [isActive]="isActive()"
          [isDesktop]="false"
          [label]="menuItem().label"
        />
      </a>
    </ng-container>
    <div
      *ngIf="menuItem().children"
      class="mobile-sub-menu"
    >
      <noda-nav-menu-item
        class="item"
        [isActive]="isActive()"
        [isDesktop]="false"
        [label]="menuItem().label"
      />
      <svg-icon
        name="nav/arrow-acc.svg#arrow-acc"
        class="icon"
        [class.active]="isActive()"
        [size]="32"
      />
    </div>
  </div>
  <ng-content select="[sub-menu]" />
</div>
