import { DOCUMENT } from '@angular/common';
import { DestroyRef, Directive, Host, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScrollDirective } from '@core/modules/directives/scroll.directive';
import { ThemeService } from '@core/services/themes.service';
import { PlatformService } from '@noda-lib/utils/services';
import { filter } from 'rxjs/operators';

import { EnThemeMode } from '../../enums/theme-mode.enum';

@Directive({
  selector: '[nodaBarTheme]',
  standalone: true,
})
export class NavBarThemeDirective implements OnInit {
  private navBarThemeMap: Map<string, EnThemeMode> = new Map([
    ['white', EnThemeMode.White],
    ['dark', EnThemeMode.Default],
    ['dark-header', EnThemeMode.DarkHeader],
  ]);
  private sections: NodeListOf<Element>;
  private observer: MutationObserver;
  private themesApplied: Set<number> = new Set();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Host() private scrollDirective: ScrollDirective,
    private themeService: ThemeService,
    private platformService: PlatformService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    if (this.platformService.isBrowser()) {
      this.initSectionsObserver();
      this.scrollDirective.scrollChange
        .pipe(
          filter(() => !!this.sections),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.updateThemeBasedOnScroll();
        });
    }
  }

  private initSectionsObserver(): void {
    this.observer = new MutationObserver(() => {
      this.sections = this.document.querySelectorAll('.section');
    });

    this.observer.observe(this.document.body, {
      childList: true,
      subtree: true,
    });
  }

  private isSectionVisible(section: Element): boolean {
    const rect: DOMRect = section.getBoundingClientRect();

    return rect.top <= 0 && rect.bottom > 0;
  }

  private applyThemeForSection(section: Element, index: number): void {
    if (!this.themesApplied.has(index)) {
      const headerClass: string = section.getAttribute('data-header-class');
      const theme: EnThemeMode = this.navBarThemeMap.get(headerClass) || EnThemeMode.Default;
      this.themeService.updateTheme(theme);
      this.themesApplied.add(index);
    }
  }

  private updateThemeBasedOnScroll(): void {
    this.sections.forEach((section, index) => {
      if (this.isSectionVisible(section)) {
        this.applyThemeForSection(section, index);
      } else {
        this.themesApplied.delete(index);
      }
    });
  }
}
