<div
  nodaTheme
  class="wrapper"
  (mouseleave)="navService.clear()"
>
  <noda-nav-items
    [activeMenuItem]="navService.activeMenuItem()"
    [isMobileMenuOpen]="navService.isMobileMenuOpen()"
  />
</div>

<!--NAV BAR-->
<noda-nav-bar nodaTheme />
