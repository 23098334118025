import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { blogNavList, menuItems, moreNavList } from '@layout/nav/db/header.db';
import { IMenuItem } from '@layout/nav/interfaces/nav.type';
import { navItemComponentImports } from '@layout/nav/items/nav-items.imports';
import { NODA_FORM_ROUTER_LINK } from '@shared/constants/links';
import { EnMaxWidthScreen, EnMinWidthScreen } from '@shared/constants/screen-breakpoints';

@Component({
  selector: 'noda-nav-items',
  templateUrl: './nav-items.component.html',
  standalone: true,
  styleUrls: ['./nav-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navItemComponentImports,
})
export class NavItemsComponent {
  public readonly buttonLink: string = NODA_FORM_ROUTER_LINK;
  public minWidthScreen: typeof EnMinWidthScreen = EnMinWidthScreen;
  public maxWidthScreen: typeof EnMaxWidthScreen = EnMaxWidthScreen;

  public moreNavList: InputSignal<IMenuItem[]> = input<IMenuItem[]>(moreNavList);
  public blogNavList: InputSignal<IMenuItem[]> = input<IMenuItem[]>(blogNavList);
  public menuItems: InputSignal<IMenuItem[]> = input<IMenuItem[]>(menuItems);
  public activeLang: InputSignal<string> = input<string>();
  public isMobileMenuOpen: InputSignal<boolean> = input<boolean>();
  public activeMenuItem: InputSignal<string> = input<string>();
}
