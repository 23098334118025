import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { ThemeService } from '@core/services/themes.service';
import { additionalData } from '@layout/footer/footer.db';
import { mobileMenuComponentImports } from '@layout/nav/components/mobile/mobile-menu.imports';
import { mobileNavList } from '@layout/nav/db/header.db';
import { INavItem } from '@layout/nav/interfaces/nav.type';
import { Item } from '@layout/types/layout.type';

@Component({
  selector: 'noda-mobile-menu',
  standalone: true,
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: mobileMenuComponentImports,
})
export class MobileMenuComponent {
  public mobileNavList: InputSignal<INavItem[]> = input<INavItem[]>(mobileNavList);
  public footerData: InputSignal<Item[]> = input<Item[]>(additionalData);
  public switcherAvailable: InputSignal<boolean> = input<boolean>(false);

  constructor(public themeService: ThemeService) {}
}
