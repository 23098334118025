import { IMetaData } from '@core/types/meta';

export const TRAVEL_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.travel.title',
  content: 'meta.use-cases.travel.description',
};

export const SAAS_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.saas.title',
  content: 'meta.use-cases.saas.description',
};

export const ECOMMERCE_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.ecommerce.title',
  content: 'meta.use-cases.ecommerce.description',
};

export const RETAIL_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.retail.title',
  content: 'meta.use-cases.retail.description',
};

export const EDUCATIONAL_PLATFORMS_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.educational-platforms.title',
  content: 'meta.use-cases.educational-platforms.description',
};

export const MARKETPLACES_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.marketplaces.title',
  content: 'meta.use-cases.marketplaces.description',
};

export const ONLINE_GAMING_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.use-cases.online-gaming.title',
  content: 'meta.use-cases.online-gaming.description',
};
export const ABOUT_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.company.title',
  content: 'meta.about.company.description',
};
export const MISSION_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.mission.title',
  content: 'meta.about.mission.description',
};
export const SECURITY_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.security.title',
  content: 'meta.about.security.description',
};
export const UX_APPROACH_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.ux-approach.title',
  content: 'meta.about.ux-approach.description',
};
export const WHY_OPEN_BANKING_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.why-open-banking.title',
  content: 'meta.about.why-open-banking.description',
};
export const WHITE_LABEL_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.white-label.title',
  content: 'meta.about.white-label.description',
};
export const BANK_PAYOUTS_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.bank-payouts.title',
  content: 'meta.bank-payouts.description',
};
export const BANK_SIGN_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.bank-sign.title',
  content: 'meta.bank-sign.description',
};
export const BANKING_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.open-banking.title',
  content: 'meta.open-banking.description',
};
export const CARD_PAYMENTS_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.card-payments.title',
  content: 'meta.card-payments.description',
};
export const CARD_PAYOUTS_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.card-payouts.title',
  content: 'meta.card-payouts.description',
};
export const CHECKOUT_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.checkout.title',
  content: 'meta.checkout.description',
};
export const NO_CODE_PAYMENT_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.no-code.title',
  content: 'meta.no-code.description',
};
export const CONTACT_US_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.contact-us.title',
  content: 'meta.contact-us.description',
};
export const FINANCIAL_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.financial-ai-toolset.title',
  content: 'meta.financial-ai-toolset.description',
};
export const MAIN_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.main.title',
  content: 'meta.main.description',
};

export const RISK_POLICY_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.risk-appetite-policy.title',
  content: 'meta.about.risk-appetite-policy.description',
};

export const AML_POLICIES_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.aml-cft-policies.title',
  content: 'meta.about.aml-cft-policies.description',
};

export const PRICING_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.pricing.title',
  content: 'meta.pricing.description',
};

export const TOC_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.toc.title',
  content: '',
};

export const MOR_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.mor.title',
  content: 'meta.mor.description',
};
export const PARTNERSHIP_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.about.partnership.title',
  content: 'meta.about.partnership.description',
};

export const DEMO_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.demo.title',
  content: 'meta.demo.description',
};

export const IPL_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.instant-payment-links.title',
  content: 'meta.instant-payment-links.description',
};

export const DOCS_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'Noda Documentation',
  content:
    'Dive in to learn about and implement our APIs. Level-up your payments with our open banking solution and data services.',
};

export const MARKETPLACE_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.marketplace.title',
  content: 'meta.marketplace.description',
};

export const CONTACT_FORM_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'meta.contact-form.title',
  content: 'meta.contact-form.description',
};
export const NEWSROOM_META_DESCRIPTION: IMetaData = {
  name: 'newsroom',
  title: 'meta.newsroom.title',
  content: 'meta.newsroom.description',
};

export const APPLY_QR_META_DESCRIPTION: IMetaData = {
  name: 'description',
  title: 'Noda Form',
  content:
    "Explore our integrated payment platform. Browse Noda's service marketplace to build your perfect payment tech stack. Open banking, cards, data enrichment, no-code.",
};
