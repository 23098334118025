import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { expandedSubItemsComponentImports } from '@layout/nav/components/expanded-sub-items/expanded-sub-items.imports';
import { IExpandedSubMenu } from '@layout/nav/interfaces/nav.type';

@Component({
  selector: 'noda-expanded-sub-items',
  standalone: true,
  templateUrl: './expanded-sub-items.component.html',
  styleUrls: ['./expanded-sub-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: expandedSubItemsComponentImports,
})
export class ExpandedSubItemsComponent {
  @Input() public items: IExpandedSubMenu;
  @Input() public isDefaultView: boolean = true;
}
