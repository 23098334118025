import { Routes } from '@angular/router';
import { productionGuard } from '@core/guards/production.guard';
import { resolveMeta } from '@core/handlers/core.handlers';
import { WrapperComponent } from '@layout/wrapper/wrapper.component';
import { languageGuard } from '@translate/guards/language.guard';
import { translatedUrlGuard } from '@translate/guards/translated-url.guard';

import { APPLY_QR_META_DESCRIPTION } from './meta-descriptions';
import { routesPaths } from './routes';

export const routes: Routes = [
  {
    path: '',
    component: WrapperComponent,
    children: routesPaths,
    canActivateChild: [translatedUrlGuard],
    data: {
      breadcrumb: {
        name: 'home',
        path: '/',
      },
    },
  },
  {
    path: 'apply-qr',
    loadComponent: () =>
      import('@pages/apply-qr/apply-qr-form.component').then(m => m.ApplyQrFormComponent),
    resolve: resolveMeta({ ...APPLY_QR_META_DESCRIPTION, robots: 'noindex, nofollow' }),
  },
  {
    path: 'rules/internal',
    loadComponent: () => import('@pages/rules/rules.component').then(m => m.RulesComponent),
    title: 'Terms & Conditions',
  },
  {
    path: 'ui-kit',
    loadComponent: () => import('@pages/ui-kit/ui-kit.component').then(m => m.UiKitComponent),
    canActivate: [productionGuard],
    title: 'UI KIT',
  },
  {
    path: '404',
    loadComponent: () =>
      import('@pages/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    pathMatch: 'full',
    title: '404',
  },
  {
    path: ':lang',
    component: WrapperComponent,
    canActivate: [languageGuard],
    canActivateChild: [translatedUrlGuard],
    children: routesPaths,
    data: {
      breadcrumb: {
        name: 'home',
        path: '/',
      },
    },
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
