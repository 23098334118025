import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ContactFormHeaderImports } from './contact-form-header.imports';

@Component({
  selector: 'noda-contact-form-header',
  standalone: true,
  templateUrl: './contact-form-header.component.html',
  styleUrls: ['./contact-form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: ContactFormHeaderImports,
})
export class ContactFormHeaderComponent {}
