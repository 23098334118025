import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  InputSignal,
  OnInit,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { mobileMenuItemsComponentImports } from '@layout/nav/components/mobile/menu-items/mobile-menu-items.imports';
import { EnMenuItemType } from '@layout/nav/enums/nav.enum';
import { INavItem, ISubItem } from '@layout/nav/interfaces/nav.type';
import { ISimple } from '@noda-lib/utils/interfaces';
import { ArticlesDataService } from '@pages/articles/services/articles.data.service';
import { TContext } from '@shared/types/shared.interfaces';

@Component({
  selector: 'noda-mobile-menu-items',
  standalone: true,
  templateUrl: './mobile-menu-items.component.html',
  styleUrls: ['./mobile-menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: mobileMenuItemsComponentImports,
})
export class MobileMenuItemsComponent implements OnInit {
  private articlesDataService: ArticlesDataService = inject(ArticlesDataService);

  public isActive: boolean[];
  public readonly menuItemType: typeof EnMenuItemType = EnMenuItemType;
  public mobileNavList: InputSignal<INavItem[]> = input.required<INavItem[]>();

  // We filter the entire list of blog elements to show or not case-studies link
  public mobileNavFilteredList: Signal<INavItem[]> = computed(() => {
    const allItems: INavItem[] = this.mobileNavList();
    const availableTagValues: string[] = this.articlesDataService
      .currentTagsForNavigation()
      .map(tag => tag.value);

    const blogItems: INavItem | undefined = allItems.find(
      (item: INavItem) => item.label === this.menuItemType.Resources,
    );

    const blogItemsFilteredChildren: (INavItem | ISubItem)[] =
      blogItems?.children?.filter((item: INavItem | ISubItem) => {
        if (!item.params) {
          return true;
        }

        return item.params['tag'] && availableTagValues.includes(item.params['tag']);
      }) || [];

    return [
      ...allItems.map((item: INavItem) =>
        item.label === this.menuItemType.Resources && blogItems
          ? ({ ...item, children: blogItemsFilteredChildren } as unknown as INavItem)
          : item,
      ),
    ];
  });

  @ViewChild('productsTemplate')
  public productsTemplate: TemplateRef<TContext<INavItem>>;
  @ViewChild('simpleSubMenuTemplate')
  private simpleSubMenuTemplate: TemplateRef<TContext<INavItem>>;
  @ViewChild('defaultExpandedSubMenuTemplate')
  private defaultExpandedSubMenuTemplate: TemplateRef<TContext<ISubItem>>;

  ngOnInit(): void {
    this.isActive = Array(this.mobileNavList.length).fill(false);
  }

  public getTemplateForLabel(label: string): TemplateRef<TContext<INavItem | ISubItem>> {
    const templateMap: ISimple<TemplateRef<TContext<INavItem | ISubItem>>> = {
      [EnMenuItemType.Products]: this.productsTemplate,
      [EnMenuItemType.Resources]: this.simpleSubMenuTemplate,
    };

    return templateMap[label] || this.defaultExpandedSubMenuTemplate;
  }

  public toggleItem(index: number, item: INavItem): void {
    // Single menu item (with no children) is always active if you click on it
    const isActive: boolean = !this.hasChildren(item) || !this.isActive[index];
    this.isActive.fill(false);
    this.isActive[index] = isActive;
  }

  private hasChildren(item: INavItem): boolean {
    return Boolean(item.children);
  }

  public getNavItems(items: (INavItem | ISubItem)[]): INavItem[] {
    return items?.filter((item): item is INavItem => 'label' in item);
  }

  public getSubMenuItem(items: (INavItem | ISubItem)[]): ISubItem[] {
    return items?.filter((item): item is ISubItem => !('label' in item));
  }
}
