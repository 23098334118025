<section class="new-year-wrapper">
  <div class="new-year-text">🌟 Noda Wishes You Happy Holidays 🌟</div>
  @for (snowflake of snowflakes; track snowflake.id) {
  <div [ngClass]="['snowflake', 'pos-' + snowflake.id]">
    <noda-snowflake
      [color]="snowflake.color"
      [opacity]="snowflake.opacity"
      [rotation]="snowflake.rotation"
      [size]="snowflake.size"
      [strokeWidth]="snowflake.strokeWidth"
    />
  </div>
  }
</section>
