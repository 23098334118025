import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  InputSignal,
  OnInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BaseNavService } from '@core/services/base-nav-service';
import { mobileMenuFooterComponentImports } from '@layout/nav/components/mobile/footer/mobile-menu-footer.imports';
import { Item } from '@layout/types/layout.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'noda-mobile-menu-footer',
  standalone: true,
  templateUrl: './mobile-menu-footer.component.html',
  styleUrls: ['./mobile-menu-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: mobileMenuFooterComponentImports,
})
export class MobileMenuFooterComponent implements OnInit {
  public footerData: InputSignal<Item[]> = input<Item[]>();
  private router: Router = inject(Router);
  private navService: BaseNavService = inject(BaseNavService);

  ngOnInit(): void {
    this.handleNavigation();
  }

  private handleNavigation(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.navService.isMobileMenuOpen.set(false));
  }
}
