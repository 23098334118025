import { Directive, ElementRef, inject, OnInit } from '@angular/core';
import { ThemeService } from '@core/services/themes.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[nodaTheme]',
  standalone: true,
})
export class ThemeDirective implements OnInit {
  private elementRef: ElementRef = inject(ElementRef);
  private themeService: ThemeService = inject(ThemeService);

  ngOnInit(): void {
    this.themeService.currentThemeMode$.pipe(untilDestroyed(this)).subscribe(theme => {
      this.applyTheme(theme);
    });
  }

  private applyTheme(themeClass: string): void {
    this.elementRef.nativeElement.className = themeClass;
  }
}
