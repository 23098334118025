<div class="bar">
  <div class="bar__wrapper">
    <svg-icon name="card-payments/bolt.svg#bolt" />
    <div
      class="bar__text"
      [innerHTML]="heroEvent.title"
    ></div>
    <svg-icon name="card-payments/bolt.svg#bolt" />
    <a
      class="bar__link"
      [routerLink]="['events', heroEvent.path]"
    >
      Book a meeting
    </a>
  </div>
</div>
