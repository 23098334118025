import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { TRANSLATED_URLS } from '@core/api/constants/translated-urls.constants';
import { AVAILABLE_LANGS, TranslateLang } from '@translate/constants/available-langs';
import { Observable, of } from 'rxjs';

export const translatedUrlGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<UrlTree | boolean> => {
  const router: Router = inject(Router);

  const reqPath: string = route.routeConfig.path;
  let langPrefix: TranslateLang | '/' = (state.root.firstChild.params as any)?.lang;

  const createUrlTree = (url: string): UrlTree => {
    return router.createUrlTree([`${langPrefix}/${url}`], {
      queryParamsHandling: 'merge',
      queryParams: { ...route.queryParams },
    });
  };

  const skipUrlTranslation: boolean = true;

  if (!reqPath && AVAILABLE_LANGS.includes(langPrefix as TranslateLang)) {
    return of(skipUrlTranslation);
  }

  if (!AVAILABLE_LANGS.includes(langPrefix as TranslateLang)) {
    langPrefix = '/';
  }

  for (const [originalUrl, translations] of Object.entries(TRANSLATED_URLS)) {
    const translatedPath: string = translations[langPrefix];

    const isReqPathInTranslations: boolean = Object.values(translations).includes(reqPath);
    const isTranslatedUrlInArray: boolean = Boolean(
      langPrefix && reqPath === originalUrl && translatedPath,
    );
    const isUrlAlreadyTranslated: boolean = Boolean(
      translatedPath && isReqPathInTranslations && translatedPath !== reqPath,
    );

    if (isUrlAlreadyTranslated || isTranslatedUrlInArray) {
      return of(createUrlTree(translatedPath));
    } else if (isReqPathInTranslations && !translatedPath) {
      return of(createUrlTree(originalUrl));
    }
  }

  return of(skipUrlTranslation);
};
