<article class="submenu-wrapper">
  <div
    class="submenu"
    [class.products]="!isDefaultView"
  >
    <ng-container
      *ngFor="let child of items?.children; let i = index; first as isFirst; trackByKey: 'index'"
    >
      <ng-container
        *ngTemplateOutlet="
          isDefaultView ? defaultTemplate : productsTemplate;
          context: { $implicit: child, index: i, isFirst: isFirst }
        "
      />
    </ng-container>
  </div>
</article>

<ng-template
  #productsTemplate
  let-child
  let-isFirst="isFirst"
>
  <div class="submenu-items">
    <h2 class="submenu-label">
      <noda-translate [key]="child.label" />
    </h2>
    <div class="submenu-sub-items">
      <noda-nav-item
        *ngFor="let item of child.items; trackByKey: 'index'"
        [link]="item.link"
      >
        <noda-nav-card
          content
          class="custom-section"
          [class.large-section]="isFirst"
          [item]="item"
        />
      </noda-nav-item>
    </div>
  </div>
</ng-template>

<ng-template
  #defaultTemplate
  let-child
>
  <noda-nav-item
    *ngFor="let item of child.items; trackByKey: 'index'"
    [link]="item.link"
  >
    <noda-nav-card
      content
      [item]="item"
    />
  </noda-nav-item>
</ng-template>
