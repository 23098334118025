import { Directive, HostListener, Input } from '@angular/core';

import { DropdownMenuDirective } from './dropdown-menu.directive';

@Directive({
  selector: '[nodaDropdownTrigger]',
  standalone: true,
})
export class DropdownTriggerDirective {
  @Input('nodaDropdownTrigger')
  public dropdownMenu!: DropdownMenuDirective;

  @HostListener('click', ['$event'])
  public toggleDropdown(): void {
    this.dropdownMenu.toggle();
  }
}
