<header
  #scrollDirective="scrollDirective"
  nodaBarTheme
  nodaScroll
>
  @if (scrollDirective.isScrolled) {
    <nav class="nav nav-bar">
      <noda-nav-logo [targetMethod]="logoTargetMethod()" />
      <div
        *nodaResponsiveIf="[minWidthScreen.LG]"
        class="scroll-up"
        (click)="scrollDirective.scrollToTop()"
      >
        <svg-icon name="header/scroll-top.svg#scroll-top" />
      </div>
      <noda-nav-actions
        btnSize="xs"
        class="scroll-btn"
        [href]="actionHref()"
      >
        <noda-nav-burger
          *nodaResponsiveIf="[maxWidthScreen.LG]"
          actions
        />
      </noda-nav-actions>
    </nav>
  }
</header>
