import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseNavService } from '@core/services/base-nav-service';
import { navComponentImports } from '@layout/nav/nav.imports';

@Component({
  selector: 'noda-nav',
  standalone: true,
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navComponentImports,
})
export class NavComponent {
  constructor(public navService: BaseNavService) {}
}
