import { Route } from '@angular/router';
import { queryParamsHandlingGuard } from '@core/guards/query-params-handling.guard';
import { resolveMeta } from '@core/handlers/core.handlers';
import { clearTagParamsGuard } from '@pages/articles/guards/clear-tags-params.guard';
import { footerArticlesResolver } from '@pages/articles/resolvers/articles.resolver';

import {
  ABOUT_DESCRIPTION,
  AML_POLICIES_META_DESCRIPTION,
  BANK_PAYOUTS_DESCRIPTION,
  BANK_SIGN_DESCRIPTION,
  BANKING_DESCRIPTION,
  CARD_PAYMENTS_DESCRIPTION,
  CARD_PAYOUTS_DESCRIPTION,
  CHECKOUT_DESCRIPTION,
  CONTACT_FORM_META_DESCRIPTION,
  CONTACT_US_DESCRIPTION,
  DEMO_META_DESCRIPTION,
  ECOMMERCE_META_DESCRIPTION,
  EDUCATIONAL_PLATFORMS_META_DESCRIPTION,
  FINANCIAL_DESCRIPTION,
  IPL_META_DESCRIPTION,
  MAIN_DESCRIPTION,
  MARKETPLACE_META_DESCRIPTION,
  MARKETPLACES_META_DESCRIPTION,
  MISSION_DESCRIPTION,
  NEWSROOM_META_DESCRIPTION,
  NO_CODE_PAYMENT_DESCRIPTION,
  ONLINE_GAMING_META_DESCRIPTION,
  PARTNERSHIP_META_DESCRIPTION,
  PRICING_META_DESCRIPTION,
  RETAIL_META_DESCRIPTION,
  RISK_POLICY_META_DESCRIPTION,
  SAAS_META_DESCRIPTION,
  SECURITY_DESCRIPTION,
  TOC_META_DESCRIPTION,
  TRAVEL_META_DESCRIPTION,
  UX_APPROACH_DESCRIPTION,
  WHITE_LABEL_DESCRIPTION,
  WHY_OPEN_BANKING_DESCRIPTION,
} from './meta-descriptions';

export const routesPaths: Route[] = [
  {
    path: '',
    loadComponent: () => import('./pages/main/main.component').then(m => m.MainComponent),
    resolve: resolveMeta(MAIN_DESCRIPTION),
  },
  {
    path: 'open-banking',
    loadComponent: () => import('./pages/banking/banking.component').then(m => m.BankingComponent),
    resolve: resolveMeta(BANKING_DESCRIPTION),
  },
  {
    path: 'card-payments',
    loadComponent: () =>
      import('./pages/card-payments/card-payments.component').then(m => m.CardPaymentsComponent),
    resolve: resolveMeta(CARD_PAYMENTS_DESCRIPTION),
  },
  {
    path: 'kartenzahlung',
    loadComponent: () =>
      import('./pages/card-payments/card-payments.component').then(m => m.CardPaymentsComponent),
    resolve: resolveMeta(CARD_PAYMENTS_DESCRIPTION),
  },
  {
    path: 'plashtane-s-karta',
    loadComponent: () =>
      import('./pages/card-payments/card-payments.component').then(m => m.CardPaymentsComponent),
    resolve: resolveMeta(CARD_PAYMENTS_DESCRIPTION),
  },
  {
    path: 'plati-cu-cardul',
    loadComponent: () =>
      import('./pages/card-payments/card-payments.component').then(m => m.CardPaymentsComponent),
    resolve: resolveMeta(CARD_PAYMENTS_DESCRIPTION),
  },
  {
    path: 'checkout',
    loadComponent: () =>
      import('./pages/checkout/checkout.component').then(m => m.CheckoutComponent),
    resolve: resolveMeta(CHECKOUT_DESCRIPTION),
  },
  {
    path: 'no-code-payment-page',
    loadComponent: () =>
      import('./pages/no-code-payment/no-code-payment.component').then(
        m => m.NoCodePaymentComponent,
      ),
    resolve: resolveMeta(NO_CODE_PAYMENT_DESCRIPTION),
  },
  {
    path: 'payment-link',
    loadComponent: () =>
      import('./pages/instant-payment-links/ipl.component').then(m => m.IplComponent),
    resolve: resolveMeta(IPL_META_DESCRIPTION),
  },
  {
    path: 'betaallink',
    loadComponent: () =>
      import('./pages/instant-payment-links/ipl.component').then(m => m.IplComponent),
    resolve: resolveMeta(IPL_META_DESCRIPTION),
  },
  {
    path: 'link-de-plata',
    loadComponent: () =>
      import('./pages/instant-payment-links/ipl.component').then(m => m.IplComponent),
    resolve: resolveMeta(IPL_META_DESCRIPTION),
  },
  {
    path: 'bank-payouts',
    loadComponent: () =>
      import('./pages/bank-payouts/bank-payouts.component').then(m => m.BankPayoutsComponent),
    resolve: resolveMeta(BANK_PAYOUTS_DESCRIPTION),
  },
  {
    path: 'card-payouts',
    loadComponent: () =>
      import('./pages/card-payouts/card-payouts.component').then(m => m.CardPayoutsComponent),
    resolve: resolveMeta(CARD_PAYOUTS_DESCRIPTION),
  },
  {
    path: 'bank-sign',
    loadComponent: () =>
      import('./pages/bank-sign/bank-sign.component').then(m => m.BankSignComponent),
    resolve: resolveMeta(BANK_SIGN_DESCRIPTION),
  },
  {
    path: 'customer-lifetime-value-prediction',
    loadComponent: () =>
      import('./pages/financial/financial.component').then(m => m.FinancialComponent),
    resolve: resolveMeta(FINANCIAL_DESCRIPTION),
  },
  {
    path: 'about/company',
    loadComponent: () =>
      import('@pages/company/company.component').then(module => module.CompanyComponent),
    resolve: { ...resolveMeta(ABOUT_DESCRIPTION), articlesData: footerArticlesResolver },
  },
  {
    path: 'about/who-we-are',
    loadComponent: () => import('./pages/mission/mission.component').then(m => m.MissionComponent),
    resolve: resolveMeta(MISSION_DESCRIPTION),
  },
  {
    path: 'about/security-and-data-handling',
    loadComponent: () =>
      import('./pages/security/security.component').then(m => m.SecurityComponent),
    resolve: resolveMeta(SECURITY_DESCRIPTION),
  },
  {
    path: 'about/customer-experience',
    loadComponent: () =>
      import('./pages/ux-approach/ux-approach.component').then(m => m.UxApproachComponent),
    resolve: resolveMeta(UX_APPROACH_DESCRIPTION),
    data: {
      theme: 'dark',
    },
  },
  {
    path: 'about/why-open-banking',
    loadComponent: () =>
      import('./pages/why-open-banking/why-open-banking.component').then(
        m => m.WhyOpenBankingComponent,
      ),
    resolve: resolveMeta(WHY_OPEN_BANKING_DESCRIPTION),
  },
  {
    path: 'plugins',
    loadChildren: () => import('@pages/plugins/plugins.routes').then(m => m.ROUTES_PLUGINS),
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./pages/contact-us/contact-us.component').then(m => m.ContactUsComponent),
    resolve: resolveMeta(CONTACT_US_DESCRIPTION),
  },
  {
    path: 'use-cases/travel',
    loadComponent: () => import('./pages/travel/travel.component').then(m => m.TravelComponent),
    resolve: resolveMeta(TRAVEL_META_DESCRIPTION),
  },
  {
    path: 'use-cases/ecommerce',
    loadComponent: () =>
      import('./pages/ecommerce/ecommerce.component').then(m => m.EcommerceComponent),
    resolve: resolveMeta(ECOMMERCE_META_DESCRIPTION),
  },
  {
    path: 'use-cases/saas',
    loadComponent: () => import('./pages/saas/saas.component').then(m => m.SassComponent),
    resolve: resolveMeta(SAAS_META_DESCRIPTION),
  },
  {
    path: 'use-cases/retail',
    loadComponent: () => import('./pages/retail/retail.component').then(m => m.RetailComponent),
    resolve: resolveMeta(RETAIL_META_DESCRIPTION),
  },
  {
    path: 'use-cases/educational-platforms',
    loadComponent: () =>
      import('./pages/educational-platforms/educational-platforms.component').then(
        m => m.EducationalPlatformsComponent,
      ),
    resolve: resolveMeta(EDUCATIONAL_PLATFORMS_META_DESCRIPTION),
  },
  {
    path: 'use-cases/marketplaces',
    loadComponent: () =>
      import('./pages/marketplaces/marketplaces.component').then(m => m.MarketplacesComponent),
    resolve: resolveMeta(MARKETPLACES_META_DESCRIPTION),
  },
  {
    path: 'use-cases/online-gaming',
    loadComponent: () =>
      import('@pages/online-gaming/gaming.component').then(
        ({ GamingComponent }) => GamingComponent,
      ),
    resolve: {
      ...resolveMeta(ONLINE_GAMING_META_DESCRIPTION),
      articlesData: footerArticlesResolver,
    },
  },
  {
    path: 'demo',
    loadChildren: () => import('@pages/demo/demo.routes').then(m => m.ROUTES_DEMO),
    resolve: resolveMeta(DEMO_META_DESCRIPTION),
  },
  {
    path: 'about/risk-appetite-policy',
    loadComponent: () =>
      import('./pages/documents/risk-aml-policies/risk-policy/risk-policy.component').then(
        m => m.RiskPolicyComponent,
      ),
    resolve: resolveMeta(RISK_POLICY_META_DESCRIPTION),
  },
  {
    path: 'about/aml-cft-policies',
    loadComponent: () =>
      import('./pages/documents/risk-aml-policies/aml-policies/aml-policies.component').then(
        m => m.AmlPoliciesComponent,
      ),
    resolve: resolveMeta(AML_POLICIES_META_DESCRIPTION),
  },
  {
    path: 'about/partnerships',
    loadComponent: () =>
      import('./pages/partners/partners.component').then(m => m.PartnersComponent),
    resolve: resolveMeta(PARTNERSHIP_META_DESCRIPTION),
  },
  {
    path: 'about/legal-privacy',
    loadChildren: () =>
      import('@pages/documents/legal-docs/legal-docs.routes').then(m => m.ROUTES_LEGAL),
  },
  {
    path: 'pricing',
    loadComponent: () => import('@pages/pricing/pricing.component').then(m => m.PricingComponent),
    pathMatch: 'full',
    resolve: resolveMeta(PRICING_META_DESCRIPTION),
  },
  {
    path: 'toc',
    loadComponent: () => import('@pages/toc/toc.component').then(m => m.TocComponent),
    pathMatch: 'full',
    resolve: resolveMeta(TOC_META_DESCRIPTION),
  },
  {
    path: 'articles',
    canActivate: [queryParamsHandlingGuard],
    loadChildren: () => import('@pages/articles/articles.routes').then(m => m.ROUTES_ARTICLES),
  },
  {
    path: 'glossary',
    loadChildren: () => import('@pages/glossary/glossary.routes').then(m => m.ROUTES_GLOSSARY),
  },
  {
    path: 'events',
    canActivate: [queryParamsHandlingGuard],
    loadChildren: () => import('@pages/events/events.routes').then(m => m.ROUTES_EVENTS),
  },
  {
    path: 'newsroom',
    canActivate: [queryParamsHandlingGuard],
    canDeactivate: [clearTagParamsGuard],
    resolve: resolveMeta(NEWSROOM_META_DESCRIPTION),
    loadComponent: () =>
      import('@pages/newsroom/newsroom.component').then(m => m.NewsroomComponent),
  },
  {
    path: 'about/grow-your-business-with-noda',
    loadComponent: () =>
      import('./pages/white-label/white-label.component').then(m => m.WhiteLabelComponent),
    resolve: resolveMeta(WHITE_LABEL_DESCRIPTION),
  },
  // TODO: marketplace
  {
    path: 'marketplace',
    loadComponent: () =>
      import('./pages/marketplace/marketplace.component').then(m => m.MarketplaceComponent),
    resolve: resolveMeta(MARKETPLACE_META_DESCRIPTION),
  },
  {
    path: 'contact-sales',
    loadComponent: () =>
      import('@pages/contact-form/contact-form.component').then(m => m.ContactFormComponent),
    resolve: resolveMeta(CONTACT_FORM_META_DESCRIPTION),
  },
];
