import { Provider, Type } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ChangeLangAttrDirective } from '@core/modules/directives/change-lang-attribute.directive';
import { provideCustomIcons } from '@core/modules/providers/icons.provider';
import { AssetsRootModule } from '@noda-lib/assets';

export const AppImports: Type<unknown>[] = [
  RouterOutlet,
  AssetsRootModule,
  ChangeLangAttrDirective,
];

export const AppProviders: Provider[] = [provideCustomIcons()];
