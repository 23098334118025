import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { NODA_DOCS_LINK, NODA_DOCS_REFERENCE_LINK } from '@shared/constants/links';
import { NODA_LIVE } from '@shared/constants/noda-links';

@Directive({
  standalone: true,
  selector: '[nodaManageLinkRel]',
})
export class ManageLinkRelDirective implements AfterViewInit {
  private htmlElement: HTMLElement;
  private href: string;

  @Input() elementLink: string;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.setElement();
    this.setHrefToCompare();

    if (this.href) {
      this.manageLinkRel();
    }
  }

  private setElement(): void {
    this.htmlElement = this.elementRef.nativeElement;
  }

  private setHrefToCompare(): void {
    this.href = this.htmlElement.getAttribute('href') || this.elementLink;
  }

  private manageLinkRel(): void {
    const relValues: string = this.htmlElement.getAttribute('rel');
    const innerHREF: boolean =
      this.href.includes(NODA_LIVE) ||
      this.href.includes(NODA_DOCS_LINK) ||
      this.href.includes(NODA_DOCS_REFERENCE_LINK) ||
      this.href.startsWith('/');

    innerHREF ? this.removeNoFollow(relValues) : this.addNoFollow(relValues);
  }

  private removeNoFollow(relValues: string): void {
    JSON.parse(relValues)
      ? this.htmlElement.setAttribute('rel', relValues.replace('nofollow', ''))
      : this.htmlElement.removeAttribute('rel');
  }

  private addNoFollow(relValues: string): void {
    JSON.parse(relValues)
      ? this.htmlElement.setAttribute('rel', `${relValues} nofollow`)
      : this.htmlElement.setAttribute('rel', 'nofollow');
  }
}
