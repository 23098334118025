import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  InputSignal,
  Output,
} from '@angular/core';
import { UtmParametersService } from '@core/services/utm-parameters.service';
import { mobileMenuItemComponentImports } from '@layout/nav/components/mobile/menu-item/mobile-menu-item.imports';
import { INavItem } from '@layout/nav/interfaces/nav.type';
import { Bind } from '@noda-lib/utils/decorators';

@Component({
  selector: 'noda-mobile-menu-item',
  standalone: true,
  templateUrl: './mobile-menu-item.component.html',
  styleUrls: ['./mobile-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [mobileMenuItemComponentImports],
})
export class MobileMenuItemComponent {
  public menuItem: InputSignal<INavItem> = input<INavItem>();
  public isActive: InputSignal<boolean> = input<boolean>();
  @Output()
  public toggle: EventEmitter<void> = new EventEmitter<void>();

  constructor(private utmService: UtmParametersService) {}

  @Bind()
  public addParamsToUrl(href: string): string {
    return this.utmService.addUtmParamsToUrl(href);
  }
}
