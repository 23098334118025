import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { navBarComponentImports } from '@layout/nav/bar/nav-bar.imports';
import { NODA_FORM_ROUTER_LINK } from '@shared/constants/links';
import { EnMaxWidthScreen, EnMinWidthScreen } from '@shared/constants/screen-breakpoints';

@Component({
  selector: 'noda-nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navBarComponentImports,
})
export class NavBarComponent {
  public readonly buttonLink: string = NODA_FORM_ROUTER_LINK;
  public minWidthScreen: typeof EnMinWidthScreen = EnMinWidthScreen;
  public maxWidthScreen: typeof EnMaxWidthScreen = EnMaxWidthScreen;
  public logoTargetMethod: InputSignal<string> = input<string>('_self');
  public actionHref: InputSignal<string> = input<string>();
}
