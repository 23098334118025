import { AVAILABLE_LANGS, TranslateLang } from '../constants/available-langs';

export function parsePath(path: string): { cleanPath: string; parentRoute: string } {
  const segments: string[] = path.split('/').filter(Boolean);
  const isLangPrefix: boolean = AVAILABLE_LANGS.includes(segments[0] as TranslateLang);
  const parentRoute: string = isLangPrefix ? segments[1] : segments[0];
  const cleanPath: string = '/' + (isLangPrefix ? segments.slice(1) : segments).join('/');

  return { cleanPath, parentRoute };
}
