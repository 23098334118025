import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

export const queryParamsHandlingGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): UrlTree | boolean => {
  const router: Router = inject(Router);

  const currentParams: Params = route.queryParams;
  const previousParams: Params = router.routerState.snapshot.root.queryParams;
  const mergedParams: Params = { ...previousParams, ...currentParams };

  if (JSON.stringify(mergedParams) === JSON.stringify(currentParams)) {
    return true;
  }

  return router.createUrlTree([state.url.split('?')[0]], { queryParams: mergedParams });
};
