export const WHITE_MODE_ROUTES: string[] = [
  '/articles',
  '/toc',
  '/glossary',
  '/plugins',
  '/contact-us',
  '/newsroom',
  'events',
  'docs',
];

export const DARK_HEADER_MODE_ROUTES: string[] = [
  '/policy',
  '/terms',
  '/cookies',
  'risk-appetite-policy',
  'aml-cft-policies',
  '/modern-slavery-statement',
  '/privacy-notice-for-job-candidates-or-cooperation',
  '/anti-bribery-and-corruption-statement',
  '/pricing',
  '/demo',
];
