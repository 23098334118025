import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[nodaAccordionToggle]',
  standalone: true,
})
export class NodaAccordionToggleDirective {
  private viewCreated: boolean;

  @Input('nodaAccordionToggle')
  public set toggle(show: boolean) {
    this.viewCreated ? this.toggleDisplay(show) : this.toggleView(show);
  }

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  private toggleDisplay(show: boolean): void {
    // because it is a structural directive its nativeElement references to a comment after the real element
    const element = this.viewContainerRef.element.nativeElement.previousSibling;

    this.renderer.setStyle(element, 'display', show ? null : 'none');
  }

  private toggleView(show: boolean): void {
    show
      ? this.viewContainerRef.createEmbeddedView(this.templateRef)
      : this.viewContainerRef.clear();

    this.viewCreated = show;
  }
}
