import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

/**
 * A workaround for this issue: https://github.com/angular/angular/issues/50787
 */
@Injectable({ providedIn: 'root' })
export class UrlNormalizerService extends DefaultUrlSerializer {
  public override parse(url: string): UrlTree {
    return super.parse(this.removeParentheses(url));
  }

  private removeParentheses(url: string): string {
    return url.replace(/\(.+\)/g, '');
  }
}
