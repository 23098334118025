import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IRecommendedProducts } from '@core/interfaces/recommended-products.interface';
import { IStrapiLocalizedData, IStrapiParams } from '@core/interfaces/strapi.interface';
import { TProductDto } from '@core/utils/products/dtos/product.dto';
import { TProductLabelDto } from '@core/utils/products/dtos/product-label.dto';
import { TProductPackageDto } from '@core/utils/products/dtos/product-package.dto';
import { TViewCategoryDto } from '@core/utils/products/dtos/product-view-category.dto';
import { toHttpParams } from '@noda-lib/utils/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceApiService {
  private http: HttpClient = inject(HttpClient);

  public getRecommendedProducts$(): Observable<IRecommendedProducts> {
    return this.http.get<IRecommendedProducts>('@marketplace/products');
  }

  public getProducts$(params: IStrapiParams = {}): Observable<IStrapiLocalizedData<TProductDto>> {
    const httpParams: HttpParams = toHttpParams({
      ...params,
      populate: 'viewCategory, labels, logo, packages',
      sort: 'priority:desc',
    });

    return this.http.get<any>('@cms/marketplace-products', { params: httpParams });
  }

  public getLabels$(
    params: IStrapiParams = {},
  ): Observable<IStrapiLocalizedData<TProductLabelDto>> {
    const httpParams: HttpParams = toHttpParams(params);

    return this.http.get<any>('@cms/marketplace-product-labels', { params: httpParams });
  }

  public getProductPackages$(
    params: IStrapiParams = {},
  ): Observable<IStrapiLocalizedData<TProductPackageDto>> {
    const httpParams: HttpParams = toHttpParams(params);

    return this.http.get<any>('@cms/marketplace-product-packages', { params: httpParams });
  }

  public getViewCategories$(
    params: IStrapiParams = {},
  ): Observable<IStrapiLocalizedData<TViewCategoryDto>> {
    const httpParams: HttpParams = toHttpParams(params);

    return this.http.get<any>('@cms/product-categories', { params: httpParams });
  }
}
