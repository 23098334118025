<div class="actions">
  <noda-button-v2
    link="/contact-sales"
    class="btn"
    [btnSize]="btnSize()"
    [isResizable]="false"
    text="common.call-to-action.get-started"
    (onButtonClick)="sendCaEvent()"
  />
  <ng-content select="[actions]" />
</div>
