import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { EnSnowflakeColor } from '@layout/footer/components/new-year-post-footer/new-year-post-footer.enum';

@Component({
  selector: 'noda-snowflake',
  templateUrl: './snowflake.component.svg',
  styleUrls: ['./snowflake.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnowflakeComponent {
  public size: InputSignal<number> = input.required<number>();
  public rotation: InputSignal<number> = input<number>(0);
  public color: InputSignal<EnSnowflakeColor> = input<EnSnowflakeColor>(EnSnowflakeColor.White);
  public opacity: InputSignal<number> = input<number>(1);
  public strokeWidth: InputSignal<number> = input<number>(1);
}
