<div class="mobile-menu">
  <noda-mobile-menu-item
    *ngFor="let menuItem of mobileNavFilteredList(); let i = index; trackByKey: 'index'"
    class="mobile-menu-item"
    [isActive]="isActive[i]"
    [menuItem]="menuItem"
    (toggle)="toggleItem(i, menuItem)"
  >
    <div
      *nodaAccordionToggle="isActive[i]"
      nodaStopPropagation
      sub-menu
      class="items"
      [class.developers]="menuItem.label | equal: menuItemType.Developers"
      [class.open]="isActive[i]"
      [class.products]="menuItem.label | equal: menuItemType.Products"
      [class.single]="menuItem.children | notDefined"
    >
      <!-- Render INavItem children -->
      <div
        *ngFor="let subItem of menuItem.children | map: getNavItems; trackByKey: 'label'"
        class="item"
        [class.single]="subItem.link"
      >
        <ng-container
          [ngTemplateOutlet]="getTemplateForLabel(menuItem.label)"
          [ngTemplateOutletContext]="{ $implicit: subItem }"
        />
      </div>

      <!-- Render ISubItem children -->
      <div
        *ngFor="let subItem of menuItem.children | map: getSubMenuItem; trackByKey: 'items'"
        class="item grid"
      >
        <ng-container
          [ngTemplateOutlet]="getTemplateForLabel(menuItem.label)"
          [ngTemplateOutletContext]="{ $implicit: subItem }"
        />
      </div>
    </div>
  </noda-mobile-menu-item>
</div>

<ng-template
  #productsTemplate
  let-subItem
>
  <h2 class="submenu-label">
    <noda-translate [key]="subItem.label" />
  </h2>
  <div class="nav-item">
    <noda-nav-item
      *ngFor="let child of subItem.items; trackByKey: 'index'"
      class="custom-section"
    >
      <a
        content
        [queryParamsHandling]="'merge'"
        [routerLink]="child.link | addLangPrefix | async"
      >
        <noda-nav-card [item]="child" />
      </a>
    </noda-nav-item>
  </div>
</ng-template>

<ng-template
  #defaultExpandedSubMenuTemplate
  let-subItem
>
  <noda-nav-item *ngFor="let child of subItem.items; trackByKey: 'index'">
    <a
      content
      [queryParamsHandling]="'merge'"
      [routerLink]="child.link | addLangPrefix | async"
    >
      <noda-nav-card [item]="child" />
    </a>
  </noda-nav-item>
</ng-template>

<ng-template
  #simpleSubMenuTemplate
  let-subItem
>
  <a
    class="sub-menu"
    [queryParams]="subItem.params"
    [queryParamsHandling]="'merge'"
    [routerLink]="subItem.link | addLangPrefix | async"
  >
    <div class="label">
      <noda-translate [key]="subItem.label" />
    </div>
  </a>
</ng-template>
