import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[nodaDropdownMenu]',
  standalone: true,
  exportAs: 'nodaDropdownMenu',
})
export class DropdownMenuDirective {
  @HostBinding('class.open') public isOpen: boolean = false;

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const hostElement: HTMLElement = this.el.nativeElement;
    const targetElement: HTMLElement = event.target as HTMLElement;

    const clickedInside: boolean = hostElement.contains(targetElement);

    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
